
$kolor1: #005CA9

$kolor2: #000000

$kolor3: #6F6F6F

$kolor4: #C0C0C0

$kolor5: #FFFFFF
