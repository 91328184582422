@import '@Styles/_font-colors.sass'
.DraftEditor-editorContainer
    position: relative
    z-index: 1

div.DraftEditor-root
    width: 100%
    overflow-y: auto
    white-space: pre-wrap
    word-break: break-word
    word-wrap: break-word

    p
        margin: 0

div.DraftEditor-editorContainer,
div.public-DraftEditor-content
    height: 100%
