@import '@Styles/_breakpoints.sass'
@import '@Styles/_colors.sass'

.dashboard
    &--container
        background-color: $colorWhite

    &--search-input
        margin-left: auto
        margin-bottom: 30px
        width: 100%
        max-width: 500px
        border-radius: 0px
        border: 0px
        border-bottom: 2px solid $colorGrey600

        & input
            padding: 6px 10px 6px 15px
            background: transparent
            border: none
            width: 450px
            max-width: calc(100vw - 40px)

            &::-webkit-input-placeholder
                font-style: normal

            &:focus-visible
                border: none
                outline: none

        & i
            color: #717171

.tabs
    display: flex
    &__item
        color: $colorBlack
        font-weight: 700
        height: 53px
        min-width: 140px
        display: flex
        justify-content: center
        align-items: center
        border-bottom: 3px solid transparent
        &:hover
            background-color: $colorPacificBlue200
            color: $colorBlack
            text-decoration: none

        &--active
            border-bottom-color: $colorPacificBlue500
            pointer-events: none

        +touch
            flex-basis: 48%

.userlist
    margin-top: 29px

    &__wrapper
        +touch
            margin: 0 -1rem
            padding: 0 1rem
            background-color: $colorGrey100

    &__block
        margin-bottom: 60px

        &--top
            margin-top: 20px
            margin-bottom: 20px
            color: #4a4a4a

    &__pagination-wrapper
        margin-top: 0
        +touch
            margin: 0 -1rem
            padding: 1.5rem 1rem
            background-color: $colorGrey100

    &__pagination
        margin-bottom: 8px
        +touch
            flex-direction: row
            flex-wrap: wrap            

    &__border-right
        border-right: 1px solid $colorGrey200

    &__item-header
        color: $colorWhite
        background-color: $colorPacificBlue600
        display: grid
        grid-auto-rows: minmax(45px, auto)
        align-items: center
        justify-content: flex-start
        font-size: 0.875rem
        border: 1px solid $colorGrey200
        margin-bottom: 5px
        min-height: 66px
        +touch
            display: none

    &__project-item
        grid-template-columns: 40px 1fr 1fr 250px 250px
        grid-template-areas: 'order project email used limit'

    &__user-item
        grid-template-columns: 40px 1fr 250px 250px
        grid-template-areas: 'order email used limit'

    &__item
        display: grid
        grid-auto-rows: minmax(45px, auto)
        font-size: 1rem
        border: 1px solid $colorGrey200
        margin-bottom: 5px
        height: 60px
        +touch
            display: flex
            flex-direction: column
            gap: 1.5rem
            height: auto
            border: 2px solid $colorGrey600
            border-radius: 5px
            padding: 1rem
            margin-bottom: 24px
            background-color: $colorWhite

        &--element
            display: flex
            align-items: center
            justify-content: flex-start
            overflow: hidden
            padding: 0 15px
            height: 100%
            &:not(:last-child)
                border-right: 1px solid $colorGrey200

            +touch
                border: none
                padding: 0
                height: 100%
                border-right: none

        &--order
            @extend .userlist__item--element
            grid-area: order
            justify-content: center
            +touch
                display: none

        &--project-wrapper
            @extend .userlist__item--element
            grid-area: project
            +touch
                display: flex

        &--quota-btn-cancel
            margin-right: 5px
            margin-left: 10px
            +touch
                margin-left: 10px

        &--quota-text
            margin: 0px 30px 0px 3px


        &--order-header
            @extend .userlist__item--element
            grid-area: order
            justify-content: center
            +touch
                justify-content: center
            
    

        &--project-header
            @extend .userlist__item--element
            grid-area: project

        &--email
            @extend .userlist__item--element
            grid-area: email

        &--email-header
            @extend .userlist__item--element
            grid-area: email

        &--text-elipsis
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        &--link
            color: $colorPrimary500
            text-decoration: none

        &--status
            @extend .userlist__item--element
            overflow: visible
            grid-area: status

            +touch
                border-bottom: none

        &--limit
            @extend .userlist__item--status
            grid-area: limit

        &--used
            @extend .userlist__item--status
            grid-area: used

        &--used-header
            @extend .userlist__item--status
            grid-area: used

        &--superadmin
            @extend .userlist__item--element
            grid-area: superadmin

        &--trusted
            @extend .userlist__item--superadmin
            grid-area: trusted

        &--btn
            @extend .userlist__item--element
            justify-content: left
            grid-area: transcriptions

        &--placeholder
            margin-right: 4px
            +touch
                display: none

        &--label
            display: none
            +touch
                flex-basis: 165px
                display: block

        &--input
            width: 70px
            border: none
            border-radius: 0px
            height: 28px
            border-bottom: 2px solid $colorPrimary500
            text-align: right
            &:disabled
                color: black
                border: none
                background: transparent
            &:focus-visible
                outline: none

    &__checkbox
        display: inline-block
        width: 12px
        height: 12px
        margin-right: 10px

    &__label
        @extend .userlist__item--text-elipsis
        margin: 0

    &__dropdown
        flex: 1
        &--btn
            background: $colorWhite
            border: transparent
            color: $colorPrimary500
            width: 100%
            font-size: 0.938rem
            max-width: 200px
            padding: 2px 4px
            text-align: left
            display: flex
            justify-content: space-between
            align-items: center
            border-bottom: 1px solid transparent
            text-transform: capitalize
            +touch
                max-width: 120px
            +mobile
                font-size: 0.875rem

            &::after
                color: #373A3C
            &:hover
                border-radius: 0
            &:hover,
            &:focus
                background: transparent
                color: $colorPrimary500
                border-bottom-color: $colorPrimary300
            &:hover
                &:focus
                    border-radius: 4px
            &:focus
                &::after
                    color: $colorWhite
