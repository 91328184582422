@import "@Styles/_colors.sass"
@import '@Styles/_breakpoints.sass'
@import "@Styles/_text.sass"

.timeline
    &__content
        display: flex
        flex-direction: row
        gap: 11px
        align-items: flex-start

        &__title
            font-family: 'Roboto', sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 140%
            @include desktop
                font-family: 'Roboto', sans-serif
                font-style: normal
                font-weight: 700
                font-size: 20px
                line-height: 150%

        &__date
            display: flex
            flex-direction: column
            gap: 5px
            @include desktop
                flex-direction: row
            span
                font-family: 'Roboto', sans-serif
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 150%
                @include desktop
                    font-family: 'Roboto', sans-serif
                    font-style: normal
                    font-weight: 700
                    font-size: 18px
                    line-height: 120%

            &--mobile
                @include desktop
                    display: none
            &--desktop
                display: none
                @include desktop
                    display: flex
            &--row
                flex-direction: column

        &__description
            @extend .text--body
            @include desktop
                display: block
        &__top
            display: flex
            justify-content: space-between
            flex-direction: column
            margin-bottom: 16px
            @include desktop
                flex-direction: row
