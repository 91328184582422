@import @Styles/_breakpoints
@import @Styles/_font-colors
@import @Styles/_colors
.search-section
    margin-top: 0
    margin-bottom: 0
    padding: 33px
    background-color: $colorPrimary600

    &__header
        font-size: 1.625rem
        text-align: center
        color: $colorGrey100
        margin-bottom: 8px
        +mobile
            margin-bottom: 20px
            font-size: 1.25rem

    &__additional-info
        color: $colorGrey100
        text-align: center
        font-size: 1rem

    &__wrapper
        display: flex
        background-color: $colorPrimary700
        justify-content: center
        flex-direction: row
        width: 100%
        flex-wrap: wrap
        gap: 10px

        &--container
            display: grid
            grid-template-columns: 1fr auto
            gap: 10px
            position: relative
            padding-top: 33px
            padding-bottom: 33px
            +desktop
                width: 100%
            +touch
                width: 100%
                grid-template-columns: auto

            &-filter
                width: 23%
                height: 50px
                border: 1px solid grey
                border-radius: 3rem 0 0 3rem
                padding: 5px 23px
                font-size: 1rem
                display: flex
                align-items: center
                justify-content: center
                white-space: nowrap
                background-color: $colorWhite
                z-index: 10
                color: $colorGrey700
                cursor: pointer

                i
                    margin-left: auto

                +touch
                    font-size: 0.875rem
                +mobile
                    font-size: 0.75rem
                    white-space: break-spaces
                    text-align: center

            &-filters
                padding-top: 55px
                padding-left: 50px
                width: 23%
                position: absolute
                top: 0px
                z-index: 0
                border: 1px solid grey
                border-radius: 2rem 0 2rem 2rem
                background-color: #FFF
                +touch
                    font-size: 0.875rem
                    padding-left: 10px
                +mobile
                    font-size: 0.75rem
                    white-space: break-spaces
                    text-align: center
                    padding-left: 5px

                button
                    margin-top: 2px
                    border: 0
                    background-color: #fff
                    cursor: pointer
                    &:hover
                        text-decoration: underline

            &-form
                display: contents
                flex-wrap: wrap
                +touch-sec
                    display: flex
                    gap: 16px
                    flex-direction: column

            &-inner-left
                display: flex
                gap: 10px
                +touch
                    display: flex
                    width: 100%
                    flex-direction: column
                    gap: 8px

                & :global(.dropdown-common button)
                    border: none
                    border-radius: 0
                    padding: 16px
                    border-radius: 5px
                    background-color: $colorWhite
                    font-size: 1rem
                    height: 64px
                    max-height: 64px
                    +touch
                        font-size: 0.875rem
                        max-height: 44px
                    &::after
                        border: none
                        content: '\e81a' // chevron-down
                        font-family: 'fontello'
                        color: $colorPrimary500
                        font-size: 25px

                & :global(.dropdown-item)
                    font-size: 1rem

            &-inner-right
                display: flex
                gap: 10px
                +touch
                    width: 100%
                    flex-direction: column

                & :global(button)
                    border-width: 2px
                    height: 100%
                    +touch
                        width: 100%

            &-input-wrapper
                display: flex
                flex: 1
                align-items: center
                background: $colorWhite
                border-radius: 5px
                max-height: 64px
                padding: 16px
                +touch
                    max-height: 44px

                & :global(i)
                    font-size: 1.25rem

            &-input
                height: 40px
                border: none
                font-size: 1rem
                width: 100%
                +mobile
                    min-width: 200px
                    font-size: 0.875rem

                &::-webkit-input-placeholder
                    font-style: normal

            &-label
                display: none

            &-btn-clear
                background-color: #CCCCCC
                border: 1px solid grey
                border-right: 0
                max-width: 50px
                padding: 5px 20px
                font-size: 1rem
                display: flex
                align-items: center
                justify-content: center
                +touch
                    font-size: 0.875rem
                +mobile
                    font-size: 0.75rem
            &-submit,
            &-submit:disabled
                background-color: $colorPrimary200
                color: $colorBlack
                max-width: 168px
                height: 64px
                border: 0
                font-size: 1.125rem
                +touch
                    max-width: unset
                    flex: 1

            &-submit:disabled
                opacity: 0.5

        &--button
            border: 1px solid $colorPrimary200
            color: $colorPrimary200
            background: transparent
            min-width: fit-content
            flex: 1
