@import '@Styles/_colors'

.addpages
	&__info
		color: $colorGrey700
		margin-top: 15px

	&__additional
		margin-top: 3rem
		padding: 0 1rem
