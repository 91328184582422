@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"
$bg-img: url("~@Assets/images/header.jpg")

.main
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-image: $bg-img
    background-repeat: no-repeat
    background-size: cover
    background-position: right
    background-color: $colorMidnightBlue700
    padding: 35px 40px
    height: 577px
    +touch
        height: auto
        padding: 35px 0
        background-position: center
    &__container
        width: 100%
        display: flex
        flex-direction: column

    &__description
        position: relative
        max-width: 780px
        color: $colorWhite
        font-size: 1rem
        line-height: 1.5rem
        margin-bottom: 3rem
        +touch
            margin-bottom: 2rem

        &--title
            font-size: 1.8125rem
            font-weight: 700
            color: $colorWhite
            margin-bottom: 2rem
            white-space: nowrap
            +touch
                font-size: 1.625rem
                white-space: break-spaces
                margin-bottom: 1rem

        &--subtitle
            color: $colorWhite
            font-size: 1.25rem
            margin: 0
            margin-bottom: 1rem
            +touch
                font-size: 1.125rem

        &--buttons
            display: flex
            align-items: center
            gap: 20px
            +touch
                flex-direction: column
                align-items: stretch
                & button
                    width: 100%

            &--upcoming
                border-color: $colorPrimary200
                color: $colorPrimary200
                background-color: transparent
                &:hover
                    border-color: $colorPrimary500
                    color: $colorPrimary500
                    background-color: $colorWhite

.description
    background-color: $colorWhite
    padding: 0px 40px 36px 40px
    +touch
        padding: 0
        padding-bottom: 35px

    &__header
        margin-top: 56px
        margin-bottom: 20px
        font-size: 1.625rem
        color: $colorBlack
        font-weight: bold
        +touch
            font-size: 1.4375rem

    &__items
        display: flex
        flex-flow: wrap
        justify-content: space-between

    &__item
        padding: 20px 20px 20px 0px
        width: 550px
        display: flex
        align-items: flex-start
        font-size: 1rem
        gap: 1rem
        +desktop
            width: 48%
        +fullhd
            width: 550px
        & span
            background-color: $colorPacificBlue600
            color: $colorWhite
            display: flex
            align-items: center
            justify-content: center
            min-width: 40px
            min-height: 40px
            font-weight: 500

        & p
            margin-top: -4px
            font-size: 1rem
            color: $colorBlack
            & a
                color: $colorMidnightBlue500
                text-decoration: underline

            +touch
                margin-bottom: 0
    
    &__more-bt
        +mobile
            width: 100%
