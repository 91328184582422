@import "@Styles/_breakpoints.sass"
@import "@Styles/_mixins.sass"
@import "@Styles/viewer/_viewer-colors.sass"

.viewer-footer
    background: $viewerColorGrey900
    color: $viewerColorWhite
    display: flex
    justify-content: right
    align-items: center
    height: 50px

    +touch-sec
        justify-content: center

    &__search
        position: relative
        width: 400px
        height: 43px
        margin: 0px 10px

        +touch-sec
            max-width: 400px
            width: calc(100% - 20px)

        .search-button
            width: 40px
            height: 39px
            background-color: $viewerColorPrimary500
            top: 0px
            right: 0px
            position: absolute
            border: $viewerColorGrey700 1px solid
            cursor: pointer

            .icon-search
                display: flex
                align-items: center
                margin: 9px
                font-size: 20px
                color: $viewerColorGrey100

                &:before
                    margin: 0
                    width: auto

        .icon-close-circle
            line-height: 40px
            width: 20px
            position: absolute
            right: 10px
            cursor: pointer
            font-size: 20px

            &:before
                margin: 0
                width: auto

        &--input
            background-color: $viewerColorBlack
            color: $viewerColorMidnightBlue200
            border: $viewerColorGrey700 1px solid
            line-height: 35px
            width: 100%
            margin: 0px
            text-indent: 10px
            padding-right: 50px

            &::-webkit-input-placeholder
                font-style: normal

            &:focus
                outline: none

    .found-number
        color: $viewerColorMidnightBlue200
        margin-right: auto
        margin-left: 45px
        font-size: 0.85rem

    .previous-result
        color: $viewerColorGrey100
        cursor: pointer
        text-decoration: underline
        font-size: 0.85rem

        +touch-sec
            margin-left: 10px

    .next-result
        color: $viewerColorGrey100
        cursor: pointer
        text-decoration: underline
        font-size: 0.85rem
        margin: 0px 10px

        +touch-sec
            margin-left: auto

    &__search-results-mobile
        width: 100%
        height: 160px
        padding: 5px 10px
        background-color: $viewerColorGrey900
        position: relative
        display: flex
        flex-direction: column
        gap: 10px

        .found-number
            color: $viewerColorMidnightBlue200
            font-size: 0.85rem
            padding: 5px 10px

        .move-between-results-mobile
            width: 100%
            display: flex
            height: 20px

        .previous-result
            color: $viewerColorMidnightBlue200
            cursor: pointer
            text-decoration: underline
            font-size: 0.85rem

            +touch-sec
                margin-left: 10px

        .next-result
            color: $viewerColorMidnightBlue200
            cursor: pointer
            text-decoration: underline
            font-size: 0.85rem
            margin: 0px 10px

            +touch-sec
                margin-left: auto

    &__search-results
        width: 100%
        height: 70px
        background-color: $viewerColorBlack
        position: relative

        .search-line
            position: absolute
            left: 45px
            top: 30px
            width: calc(100% - 65px)
            height: 16px

            .line
                background-color: $viewerColorSearchLine
                position: absolute
                left: 0px
                top: 50%
                width: 100%
                height: 2px

            .end-line-left
                background-color: $viewerColorSearchLine
                position: absolute
                left: 0px
                top: 0px
                height: 100%
                width: 2px

            .end-line-right
                background-color: $viewerColorSearchLine
                position: absolute
                right: 0px
                top: 0px
                height: 100%
                width: 2px

            .page-flags
                position: relative
                width: calc(100% - 2px)
                height: 100%
                margin-left: 0px
                margin-right: 2px

                .page-flag
                    position: absolute
                    height: 25px
                    width: 2px
                    background-color: white
                    top: calc(50% - 25px)

                    .page-number
                        bottom: -6px
                        left: 5px
                        position: absolute
                        color: $viewerColorGrey400
                        display: none
                        white-space: nowrap

                    &.selected-flag
                        background-color: $viewerColorSearchFlag !important
                        height: 45px !important
                        top: calc(50% - 25px) !important

                        .page-number
                            display: inline !important

                    &.flag-on-right

                        .page-number
                            left: initial
                            right: 5px

                    .flag
                        width: 16px
                        height: 12px
                        background-color: inherit
                        left: 0px
                        top: 0px
                        cursor: pointer

    &__pages
        display: flex
        
        &--move
            width: 64px
            height: 40px
            border: 3px $viewerColorPrimary500 solid
            border-radius: 4px
            font-size: 1.5rem
            text-align: center

        &--numbers
            margin: 0px 20px
            white-space: nowrap
            word-spacing: 1rem

            input
                height: 40px
                width: 40px
                text-align: center
                background-color: $viewerColorBlack
                color: #D5D5D7
                border-radius: 4px
                border: solid #D5D5D7 1px