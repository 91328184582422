@import "@Styles/_colors.sass"
@import '@Styles/_font-colors.sass'

html, body, #root
    min-height: 100vh

html
    font-size: 1rem
    &.medium
        font-size: 1.125rem
    &.big
        font-size: 1.25rem

body
    font-family: 'Roboto', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $colorBlack

#root
    display: flex
    flex-direction: column

main
    flex: 1
    background-color: $colorWhite

a[disabled]
    pointer-events: none

a
    color: #005CA9
    &:hover,
    &:focus
        color: #005CA9
    &[target="_blank"]
        &::after
            font-family: "fontello"
            font-style: normal
            font-weight: normal
            speak: never

            display: inline-block
            text-decoration: none
            width: 1em
            margin-right: .2em
            text-align: center
            /* opacity: .8; */

            /* For safety - reset parent styles, that can break glyph codes*/
            font-variant: normal
            text-transform: none

            /* fix buttons height, for twitter bootstrap */
            line-height: 1em

            /* Animation center compensation - margins should be symmetric */
            /* remove if not needed */
            margin-left: .7em

            /* you can be more comfortable with increased icons size */
            /* font-size: 120%; */

            /* Font smoothing. That was taken from TWBS */
            -webkit-font-smoothing: antialiased
            -moz-osx-font-smoothing: grayscale

            /* Uncomment for 3D effect */
            /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

            content: '\e82c'
            font-size: 0.875em
            //margin-left: 5px
            position: relative
            top: -0.1em

button[disabled]
    opacity: 0.5

button
    color: $kolor1
    background: $kolor5
    border-color: $kolor1
    &:hover
        color: $kolor5
        background-color: $kolor1
    &:focus
        color: $kolor1
        background-color: $kolor5
        box-shadow: 0 5px 15px rgba(0, 92, 169, .4)
    &:active
        color: $kolor5
        background-color: $kolor1
        box-shadow: 0 5px 15px rgba(0, 92, 169, .4)

input[type='number']
    -moz-appearance: textfield

    &::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0
    &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

p
    font-family: 'Roboto', sans-serif
    font-size: 0.875rem
    font-weight: 400

html.mourn
    -webkit-filter: grayscale(1)!important
    filter: grayscale(1)!important
    header
        z-index: 999!important
        .show
            z-index: 1000!important

.asterisk
    color: $colorMidnightBlue700
    margin-left: 6px
    margin-right: 6px

.form-check
    display: block
    min-height: 1.5rem
    padding-left: 1.5em
    margin-bottom: 0.125rem
    margin-left: 1rem
    margin-top: 0.3rem        

    &-input[type=radio]
        border-radius: 50%
        width: 1em
        height: 1em
        margin-top: 0.25em
        vertical-align: top
        background-color: $colorWhite
        background-repeat: no-repeat
        background-position: center
        background-size: contain
        border: 1px solid $colorPacificBlue500
        appearance: none
        color-adjust: exact
        float: left
        margin-left: -1.5em   

    &-input:active
        filter: brightness(90%)

    &-input:focus
        border-color: $colorPacificBlue200
        outline: 0

    &-input:checked
        background-color: $colorPacificBlue500
        border-color: $colorPacificBlue500

    &-input:checked[type=checkbox]
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")

    &-input:checked[type=radio]
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")

    &-input[type=checkbox]:indeterminate
        background-color: #fdba30
        border-color: #fdba30
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")

    &-input:disabled
        pointer-events: none
        filter: none
        opacity: 0.5

    &-input[disabled] ~ &-label, &-input:disabled ~ &-label
        opacity: 0.5
