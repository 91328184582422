@font-face
  font-family: 'fontello'
  src: url(~@Assets/icons/fontello.eot?6169990)
  src: url(~@Assets/icons/fontello.eot?6169990#iefix) format("embedded-opentype"), url(~@Assets/icons/fontello.woff2?6169990) format("woff2"), url(~@Assets/icons/fontello.woff?6169990) format("woff"), url(~@Assets/icons/fontello.ttf?6169990) format("truetype"), url(~@Assets/icons/fontello.svg?6169990#fontello) format("svg")
  font-weight: normal
  font-style: normal

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it.
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be
/*
 *@media screen and (-webkit-min-device-pixel-ratio:0) {
 *  @font-face {
 *    font-family: 'fontello';
 *    src: url('../font/fontello.svg?6169990#fontello') format('svg');
 *  }
 *}

[class^="icon-"]:before, 
[class*=" icon-"]:before,
.icon:before
  font-family: "fontello"
  font-style: normal
  font-weight: normal
  speak: never
  display: inline-block
  text-decoration: inherit
  width: 1em
  margin-right: .2em
  text-align: center

  /* opacity: .8;

  /* For safety - reset parent styles, that can break glyph codes
  font-variant: normal
  text-transform: none

  /* fix buttons height, for twitter bootstrap
  line-height: 1em

  /* Animation center compensation - margins should be symmetric
  /* remove if not needed
  margin-left: .2em

  /* you can be more comfortable with increased icons size
  /* font-size: 120%;

  /* Font smoothing. That was taken from TWBS
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  /* Uncomment for 3D effect
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);

.icon-question:before
  content: '\e800'

/* ''

.icon-instapaper-1:before
  content: '\e801'

/* ''

.icon-instapaper:before
  content: '\e802'

/* ''

.icon-header-1-end:before
  content: '\e803'

/* ''

.icon-header-2-end:before
  content: '\e804'

/* ''

.icon-header-3-end:before
  content: '\e805'

/* ''

.icon-list-item-end:before
  content: '\e806'

/* ''

.icon-list-end:before
  content: '\e807'

/* ''

.icon-accept:before
  content: '\e808'

/* ''

.icon-table-end:before
  content: '\e809'

/* ''

.icon-add:before
  content: '\e80a'

/* ''

.icon-accept-double:before
  content: '\e80b'

/* ''

.icon-add-area:before
  content: '\e80c'

/* ''

.icon-add-circle:before
  content: '\e80d'

/* ''

.icon-add-reversed:before
  content: '\e80e'

/* ''

.icon-arrow-double-left-full:before
  content: '\e80f'

/* ''

.icon-arrow-double-right-full:before
  content: '\e810'

/* ''

.icon-arrow-down-full:before
  content: '\e811'

/* ''

.icon-arrow-left-full:before
  content: '\e812'

/* ''

.icon-arrow-right-full:before
  content: '\e813'

/* ''

.icon-arrow-up-full:before
  content: '\e814'

/* ''

.icon-bold:before
  content: '\e815'

/* ''

.icon-books:before
  content: '\e816'

/* ''

.icon-box:before
  content: '\e817'

/* ''

.icon-chevron-double-left:before
  content: '\e818'

/* ''

.icon-chevron-double-right:before
  content: '\e819'

/* ''

.icon-chevron-down:before
  content: '\e81a'

/* ''

.icon-chevron-left:before
  content: '\e81b'

/* ''

.icon-verses-desc:before
  content: '\e81c'

/* ''

.icon-fit-to-window:before
  content: '\e81d'

/* ''

.icon-fix-to-screen:before
  content: '\e81e'

/* ''

.icon-user:before
  content: '\e81f'

/* ''

.icon-chevron-right:before
  content: '\e820'

/* ''

.icon-chevron-up:before
  content: '\e821'

/* ''

.icon-close:before
  content: '\e822'

/* ''

.icon-close-circle:before
  content: '\e823'

/* ''

.icon-comment:before
  content: '\e824'

/* ''

.icon-compare:before
  content: '\e825'

/* ''

.icon-cookies:before
  content: '\e826'

/* ''

.icon-dash-short:before
  content: '\e827'

/* ''

.icon-dash-wide:before
  content: '\e828'

/* ''

.icon-dot:before
  content: '\e829'

/* ''

.icon-music:before
  content: '\e82a'

/* ''

.icon-indent-right:before
  content: '\e82b'

/* ''

.icon-link-ext:before
  content: '\e82c'

/* ''

.icon-info:before
  content: '\e82d'

/* ''

.icon-index-person:before
  content: '\e82e'

/* ''

.icon-index-place:before
  content: '\e82f'

/* ''

.icon-download:before
  content: '\e830'

/* ''

.icon-edit:before
  content: '\e831'

/* ''

.icon-external:before
  content: '\e832'

/* ''

.icon-eye:before
  content: '\e833'

/* ''

.icon-eye-disabled:before
  content: '\e834'

/* ''

.icon-files2:before
  content: '\e835'

/* ''

.icon-forward:before
  content: '\e836'

/* ''

.icon-full-screen:before
  content: '\e837'

/* ''

.icon-full-screen-close:before
  content: '\e838'

/* ''

.icon-hamburger:before
  content: '\e839'

/* ''

.icon-index-term:before
  content: '\e83a'

/* ''

.icon-div:before
  content: '\e83b'

/* ''

.icon-undo:before
  content: '\e83c'

/* ''

.icon-usunięta-partia-tekstu-_del_:before
  content: '\e83d'

/* ''

.icon-verse-management:before
  content: '\e83e'

/* ''

.icon-paragraph:before
  content: '\e83f'

/* ''

.icon-header:before
  content: '\e840'

/* ''

.icon-header2:before
  content: '\e841'

/* ''

.icon-header3:before
  content: '\e842'

/* ''

.icon-heart:before
  content: '\e843'

/* ''

.icon-home:before
  content: '\e844'

/* ''

.icon-image-1:before
  content: '\e845'

/* ''

.icon-italic:before
  content: '\e846'

/* ''

.icon-link:before
  content: '\e847'

/* ''

.icon-link2:before
  content: '\e848'

/* ''

.icon-list:before
  content: '\e849'

/* ''

.icon-zywa-pagina:before
  content: '\e84a'

/* ''

.icon-tei-add:before
  content: '\e84b'

/* ''

.icon-eye_hide:before
  content: '\e84c'

/* ''

.icon-eye_show:before
  content: '\e84d'

/* ''

.icon-text-formatting:before
  content: '\e84e'

/* ''

.icon-tei-gap:before
  content: '\e84f'

/* ''

.icon-list-end-1:before
  content: '\e850'

/* ''

.icon-locked:before
  content: '\e851'

/* ''

.icon-mail:before
  content: '\e852'

/* ''

.icon-more:before
  content: '\e853'

/* ''

.icon-page-description:before
  content: '\e854'

/* ''

.icon-page-number:before
  content: '\e855'

/* ''

.icon-question-reversed:before
  content: '\e856'

/* ''

.icon-quote:before
  content: '\e857'

/* ''

.icon-reload:before
  content: '\e858'

/* ''

.icon-remove-reversed:before
  content: '\e859'

/* ''

.icon-circle-accept:before
  content: '\e85a'

/* ''
  
.icon-move-verse-10-up:before 
  content: '\e85b'

/* ''

.icon-move-verse-10-down:before
  content: '\e85c' 
  
/* ''

.icon-add-annotation-rect:before
  content: '\e85d'

/* ''

.icon-search:before
  content: '\e860'

/* ''

.icon-settings:before
  content: '\e861'

/* ''

.icon-share:before
  content: '\e862'

/* ''

.icon-symbol:before
  content: '\e863'

/* ''

.icon-to-end:before
  content: '\e864'

/* ''

.icon-to-start:before
  content: '\e865'

/* ''

.icon-transcription:before
  content: '\e866'

/* ''

.icon-unlocked:before
  content: '\e867'

/* ''

.icon-verse-auto-transcription:before
  content: '\e868'

/* ''

.icon-verse-clear:before
  content: '\e869'

/* ''

.icon-verse-comment:before
  content: '\e870'

/* ''

.icon-verse-delete:before
  content: '\e871'

/* ''

.icon-verse-down:before
  content: '\e872'

/* ''

.icon-verse-footer:before
  content: '\e873'

/* ''

.icon-verse-header:before
  content: '\e874'

/* ''

.icon-verse-move:before
  content: '\e875'

/* ''

.icon-verse-up:before
  content: '\e876'

/* ''

.icon-zoom-in:before
  content: '\e877'

/* ''

.icon-zoom-out:before
  content: '\e878'

/* ''

.icon-text-italic:before
  content: '\e879'

/* ''

.icon-remove-area:before
  content: '\e87a'

/* ''

.icon-subst:before
  content: '\e87b'

/* ''

.icon-subscript2:before
  content: '\e87c'

/* ''

.icon-redo:before
  content: '\e87d'

/* ''

.icon-text-structure:before
  content: '\e87e'

/* ''

.icon-tei:before
  content: '\e87f'

/* ''

.icon-change-site:before
  content: '\e880'

/* ''

.icon-image:before
  content: '\e881'

/* ''

.icon-image-description:before
  content: '\e882'

/* ''

.icon-metadata:before
  content: '\e883'

/* ''

.icon-site-transcription:before
  content: '\e884'

/* ''

.icon-table:before
  content: '\e885'

/* ''

.icon-table-cell-end:before
  content: '\e886'

/* ''

.icon-table-verse-end:before
  content: '\e887'

/* ''

.icon-circle-warning-empty:before
  content: '\e888'

/* ''

.icon-lock-close2:before
  content: '\e889'

/* ''

.icon-tei-pb:before
  content: '\e88a'

/* ''

.icon-add-annotation:before
  content: '\e88b'

/* ''

.icon-add-note-area:before
  content: '\e88c'

/* ''

.icon-add-verse:before
  content: '\e88d'

/* ''

.icon-add-word:before
  content: '\e88e'

/* ''

.icon-lock-open2:before
  content: '\e890'

/* ''

.icon-accept2:before
  content: '\e891'

/* ''

.icon-plus3:before
  content: '\e892'

/* ''

.icon-plus2:before
  content: '\e893'

/* ''

.icon-add-annotation-point:before
  content: '\e894'

/* ''

.icon-strike:before
  content: '\f0cc'

/* ''

.icon-underline:before
  content: '\f0cd'

/* ''

.icon-sort:before
  content: '\f0dc'

/* ''

.icon-sort-down:before
  content: '\f0dd'

/* ''

.icon-sort-up:before
  content: '\f0de'

/* ''

.icon-circle-empty:before
  content: '\f10c'

/* ''

.icon-subscript:before
  content: '\f12c'

/* ''

.icon-map-o:before
  content: '\f278'

/* ''

.icon-wpforms:before
  content: '\f298'

/* ''

.icon-slash:before 
  content: '/'