.frame
    &__wrapper
        margin-top: 2rem
        background-color: #EAF1F5
        border: 1px solid #B5D3ED
        border-radius: 7px
        padding: 1rem
        color: #161615

        h3
            font-size: 1rem
            font-weight: bold

        div
            font-size: 1rem
