@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

$logo-uwr: url("~@Assets/images/uwr_logo.svg")
$logo-translab: url("~@Assets/images/wlt_logo_small.svg")
$logo-uwr-dark: url("~@Assets/images/uwr_logo_dark.svg")
$logo-translab-dark: url("~@Assets/images/translab_logo_dark.svg")

.header
    position: fixed
    z-index: 1020
    width: 100%
    background-color: $colorMidnightBlue700
    height: 148px
    +touch
        height: 80px

    &__inner
        display: flex
        height: 100%
        justify-content: space-between
        align-items: center
        +touch
            padding: 0 16px
            max-width: unset

    &__home
        height: 148px
        position: relative
        +touch
            height: 80px
        &::after
            +desktop
                content: ''
                position: absolute
                width: 100%
                height: 100%
                left: 0
                top: 0

    &__subpage
        @extend .header__home
        +touch
            div[class*="main-header"]
                background-color: white

            div[class*="uwr-logo"]
                background-image: $logo-uwr-dark
            
            div[class*="translab-logo"]
                background-image: $logo-translab-dark

            button[class*="hamburger-bt"]
                color: $colorPrimary700
            
    &__wrapper
        padding: 50px 0 60px
        +touch
            padding: 0
            overflow: auto

    &__column
        display: flex
        justify-content: space-between
        +desktop
            align-items: center

    &__container
        margin-top: 85px
        background: transparent
        justify-content: space-between

    &__logo
        height: 30px
        width: 92px
        margin: 0px 16px 0px 0px
        float: left
        background-image: $logo-uwr
        background-size: cover
        text-indent: -999999rem
        +small-devices
            height: 35px
            width: 108px

        +tablet
            height: 46px
            width: 120px
        
        +widescreen
            height: 54px
            width: 169px
            margin: 0px 40px 0px 0px


    &__logo-translab
        height: 30px
        width: 136px
        float: left
        background-image: $logo-translab
        background-size: cover
        color: transparent
        text-indent: -9999px
        +small-devices
            height: 35px
            width: 160px
        +tablet
            height: 46px
            width: 218px
        +widescreen
            height: 54px
            width: 252px

    &__hamburger
        border: none
        color: $colorWhite
        background: none
        font-size: 32px
        display: none
        &:hover, &:focus, &:active, &:focus-visible, &:focus-within
            background-color: transparent
            box-shadow: none
            color: $colorWhite

        +touch
            display: block
    
    &-dropdown
        &__button
            font-size: 1.125rem
            height: auto
            width: auto
            border: none
            padding: 0
            margin: 0
            font-weight: 700
            line-height: 20px
            color: $colorGrey100
            display: flex
            align-items: center

            &::after
                font-family: 'fontello'
                content: '\e81a' // chevron-down
                color: $colorGrey100
                border: none
                font-size: 20px
                margin-right: -7px

            +touch
                padding-left: 0
                margin-left: 0
                color: $colorGrey100

            &:hover,
            &:focus
                background-color: transparent
                color: $colorMidnightBlue300
                &::after
                    color: $colorMidnightBlue300

            &[aria-expanded="true"]
                &::after
                    transform: rotate(180deg)

            +touch
                display: flex
                justify-content: space-between
                align-items: center
                width: 100%
                height: 38px
                font-size: 1rem
                background-color: #F8F8F8
                border-color: #D3D3D3
                &::after
                    color: #404244

                &:hover
                    background-color: #F8F8F8
                    border-color: #D3D3D3
                    color: #373A3C
                    font-size: 1rem

                &[aria-expanded="true"]
                    background-color: #F8F8F8
                    border-color: #D3D3D3

            &:focus
                outline: none

        &__menu
            border-radius: 0px 0px 5px 5px
            box-shadow: 0px 3px 6px 0px #00000029
        &__item
            color: $colorBlack !important
            padding: 0.5rem 2rem 0.5rem 1rem !important


