@import '@Styles/_breakpoints.sass'
@import '@Styles/_colors.sass'

.group
    padding-bottom: 40px
    color: $colorBlack

    & h1
        font-size: 1.625rem
        +touch
            font-size: 1.4375rem

    &__divider
        border-top: 1px solid #DDDDDD
        margin: 65px 0
        +touch
            margin: 25px 0

    &__header
        display: flex
        justify-content: space-between
        margin-bottom: 29px

    &__name
        &--title
            margin-bottom: 8px
        &--form
            &:hover,
            &:focus

        &--input
            border-top-right-radius: 5px
            border-bottom-right-radius: 5px
            background-color: $colorWhite
            &:disabled
                background-color: $colorGrey100

    &__add-user
        font-size: 1rem
        max-width: 600px
        margin-top: 70px

        button
            width: 100%

        &--header
            font-size: 1.4375rem
            font-weight: bold
            +touch
                font-size: 1.25rem

        &--form
            display: flex
            align-items: flex-start
            & input
                border-radius: 5px 0 0 5px
            & label
                margin: 0
        
        &--input
            flex: 1

        &--submit
            height: 3rem
            border-radius: 0 5px 5px 0
            flex-basis: 170px
            border-left: none !important        

        &--description
            font-size: 1rem


    &__userlist
        margin-top: 29px

        &--item
            border-radius: 5px
            border: 1px solid $colorGrey300
            background-color: $colorGrey100
            display: grid
            grid-template-columns: 44px 1fr 200px 82px
            height: 42px
            align-items: stretch
            font-size: 1rem
            margin-bottom: 10px

            &>:last-child
                border-right: none

            &>button
                &:focus, &:focus-visible, &:hover
                    box-shadow: none
                    outline: none
                    text-decoration: underline

            +mobile
                display: flex
                flex-direction: column
                height: auto
                padding: 10px 15px

            &-order
                display: flex
                align-items: center
                justify-content: flex-end
                padding-right: 10px
                border-right: 1px solid $colorGrey300
                +mobile
                    justify-content: flex-start
                    padding: 0
                    border-right: 0
                    margin-bottom: 5px

            &-email
                display: flex
                align-items: center
                justify-content: flex-start
                padding: 0 10px
                border-right: 1px solid $colorGrey300
                +mobile
                    padding: 0
                    border-right: 0
                    margin-bottom: 5px
                +mobile-small
                    flex-direction: column
                    align-items: flex-start

            &-role
                display: flex
                align-items: center
                justify-content: flex-start
                padding: 0 10px
                border-right: 1px solid $colorGrey300
                background-color: $colorWhite
                +mobile
                    flex-direction: column
                    align-items: flex-start
                    border-right: 0
                    margin-bottom: 5px
                    padding: 0

            &-button
                display: flex
                justify-content: center
                align-items: center
                background-color: $colorWhite
                border-top-right-radius: 5px
                border-bottom-right-radius: 5px
                +mobile
                    justify-content: flex-start
                    background-color: transparent

            &-placeholder
                color: #666666
                margin-right: 8px

    &__role
        
        &--admin
            @extend .group__userlist--item-role
            background: $colorGrey100
            text-transform: capitalize
            +mobile
                padding: 0
        &--dropdown
            font-size: 1rem
            flex: 1
            +mobile
                width: 100%
            
            &-btn
                background: $colorWhite
                border: transparent
                color: $colorPrimary500
                width: 100%
                padding: 2px 4px
                text-align: left
                display: flex
                justify-content: space-between
                align-items: center
                border-bottom: 1px solid transparent
                text-transform: capitalize
                border-radius: 0

                &::after
                    content: '\e81a' // chevron-down
                    font-family: 'fontello'
                    right: 0
                    margin-left: 5px
                    position: relative
                    border: none
                    top: 0

                &[aria-expanded="true"]
                    border: none
                    background: $colorMidnightBlue200
                    &::after
                        content: '\e821' //chevron-up

                &:hover, &:focus, &:active
                    background: transparent
                    color: $colorPrimary500
                    border-bottom-color: $colorPrimary500
                    box-shadow: none !important
                    
