.transcription-import
    &__subtitle
        font-size: 1rem
        color: #373A3C
        font-weight: bold
        margin-top: 50px
    &__description
        font-size: 1rem
        color: #687078
        margin: 40px 0 30px
    &__input
        margin-top: 20px

    &__remember
        margin-top: 20px
        font-size: 1rem
        color: #687078
    &__sources
        margin-top: 24px
        color: #005CA9
        text-decoration: none

    &__dropdown
        width: 100%
        max-width: 400px
