@import "@Styles/_breakpoints.sass"
@import "@Styles/_text.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

@import "@Pages/Transcription/TranscriptionVerification/TranscriptionVerification.module.sass"

=permissions-grid
    display: grid
    grid-template-columns: 56px minmax(200px, 1fr) 150px repeat(4, minmax(100px, 200px)) 100px

.permissions
    padding: 35px 0 100px

    p
        font-size: 0.875rem
        margin-bottom: 0

    &__additional-text
        margin-top: 50px
        margin-bottom: 35px
        font-weight: bold
    &__main
        padding-bottom: 30px
        +touch
            padding-bottom: 0px

    &__project-info
        margin-bottom: 30px
        span
            color: $colorMidnightBlue500

    &__status
        margin-top: 53px
        margin-bottom: 64px

    &__add
        &--container
            max-width: 700px
        &--wrapper
            margin-top: 8px
            flex: 1
            position: relative
        &--form
            display: flex
            flex-direction: column

            &-submit
                margin-top: 20px
                margin-left: auto
                width: 200px

            &-input
                background-color: $colorWhite
                padding: 1rem 1rem
                height: 3rem
                border-radius: 5px
                border: 1px solid $colorGrey600
                color: $colorBlack

                &:active
                    background-color: white
                &::placeholder
                    font-size: 1rem
                    font-style: normal
                    color: $colorGrey600
                label
                    display: none

                &--expanded
                    @extend .permissions__add--form-input
                    border-radius: 5px 5px 0 0

    &__input-info
        color: $colorGrey700
        font-size: 1rem
        margin-top: 6px
        i
            margin-right: 6px

    &__userlist
        +touch
            background-color: $colorGrey100

        &-header
            margin-top: 20px
            height: 62px
            background-color: $colorGrey200
            color: $colorBlack
            font-size: 1.25rem
            font-weight: 700
            display: flex
            align-items: center
            padding: 0 1.25rem
            +touch
                background-color: $colorGrey100
                font-size: 1.125rem

        &--item
            +permissions-grid
            border: 1px solid $colorGrey200
            margin-bottom: 4px
            +touch
                grid-template-columns: 1fr
                background-color: $colorWhite
                border: 1px solid $colorGrey600
                border-radius: 5px
                margin: 0 1rem 1rem

            &-descplaceholder
                display: none
                +touch
                    display: block
                    width: 200px

                +mobile
                    width: 120px

            &-mobilebutton
                display: none
                +touch
                    display: block
                    width: 100%
                    margin-top: 8px

            &-desktopbutton
                display: flex
                +touch
                    display: none

        &--element
            display: flex
            justify-content: flex-end
            align-items: center
            border-right: 1px solid $colorGrey200
            padding: 1rem
            &:nth-child(3)
                text-transform: capitalize

            p
                font-size: 1rem
                margin: 0

            +desktop
                &:nth-child(1)
                    // no
                    justify-content: center

                &:nth-child(2)
                    // email
                    justify-content: flex-start
                    align-items: flex-start
                    flex-direction: column
                    i
                        &::before
                            font-size: 1.5rem
                            margin: 0 0 0 -3px

                &:nth-child(3)
                    justify-content: flex-start

                &:last-child
                    border-right: none
                    justify-content: center

            +touch
                justify-content: flex-start
                flex-direction: row
                &:nth-child(2), &:nth-child(3)
                    flex-wrap: wrap

        &--caption
            @extend .permissions__userlist--item
            background-color: $colorPacificBlue600
            color: white
            height: 56px
            +touch
                display: none

            >div
                padding: 0 1rem
                &:nth-child(2)
                    justify-content: center

        &--mobile-dropdown
            button[class*="btn-dropdown"]
                text-transform: capitalize
            +touch
                width: 100%
                margin-top: 8px
                button[class*="btn-dropdown"]
                    padding: 10px
                    border-radius: 5px
                    border: 1px solid $colorGrey600 !important
                    font-size: 0.875rem
                    max-height: 44px
                &:after
                    font-size: 1.75rem
                    &:hover, &:focus, &:focus-visible
                        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
                        border-color: $colorMidnightBlue500 !important
                        outline: 0

            &-button
                display: flex
                justify-content: center
                align-items: center
                background-color: #FFF
                border-radius: 0
                +mobile
                    justify-content: flex-end
                    border-top: 1px solid #D5D5D5
                    padding: 13px 19px

            &-placeholder
                color: #666666
                margin-right: 8px

    &__dropdown-autocomplete
        background-color: $colorWhite
        width: 100%
        padding: 0.5rem 0
        border: 1px solid $colorGrey600
        border-radius: 0 0 5px 5px
        position: absolute
        z-index: 100
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25)
        &--item
            color: $colorBlack
            padding: 6px 16px
            &:hover
                background-color: $colorGrey200
                cursor: pointer

    &__group
        &--item
            padding-bottom: 8px
            button
                margin-left: 10px

    &__submit
        &--wrapper
            margin-top: 1.25rem
            display: flex
            justify-content: flex-end

        &--bt
            font-size: 1rem
            padding: 15px 30px
