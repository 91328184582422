@import "@Styles/_breakpoints.sass"
@import "@Styles/_colors.sass"

.add-layer
    &__container
        max-width: 1220px
        background-color: $colorGrey100
        margin-left: auto
        margin-right: auto
        display: flex
        flex-direction: column
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12)
        margin-bottom: 4rem
        padding: 3rem
        +mobile
            box-shadow: none
            padding: 1.5rem 1rem
            margin: 1rem -15px 3rem

    &__header
        &--title
            +mobile
                font-size: 1.4375rem

    &__paragraph
        font-size: 1rem
        margin-top: 1rem
        margin-bottom: 0

    &__block
        margin-top: 2rem
        &--small
            margin-top: 1rem
        &--label
            font-size: 1rem

        +mobile
            margin-top: 3rem

    &__btn
        &--container
            display: flex
            gap: 1.5rem
            justify-content: flex-end
            margin: 2rem 0
            +mobile
                flex-direction: column-reverse
            
        &--cancel
            +mobile
                width: 100%

        &--submit
            padding: 15px 30px
            font-size: 1rem
