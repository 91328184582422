@import "@Styles/_colors.sass"

.alert
    position: relative
    width: 100%
    margin: 15px auto
    border-radius: 5px
    padding: 15px 26px
    max-height: 3rem
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)

    p
        font-size: 1rem
        font-weight: 500
        margin: 0
        width: 100%

    &--danger
        @extend .alert
        color: $colorError500
        background-color: $colorError200

    &--success
        @extend .alert
        color: $colorBlack
        background-color: $colorSuccess200
        i
            color: $colorSuccess600

    &--info
        @extend .alert
        color: $colorBlack
        background-color: $colorGrey100
        border-color: none
        i
            color: $colorMidnightBlue500

.icon
    flex: 0 0 20px
    font-size: 18px

.close
    font-size: 26px
    cursor: pointer