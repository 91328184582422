@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"
@import "@Styles/_breakpoints.sass"

.form
    &__input
        &-row
            position: relative
            margin-left: 0
            margin-right: 0

            input
                background-color: $colorWhite
                padding: 1rem 1rem
                height: 3rem
                border-radius: 5px
                border: 1px solid $colorGrey600
                color: $colorBlack

                &::-webkit-input-placeholder
                    color: $colorGrey600

            & .form-label
                font-size: 0.875rem
                color: #6F6F6F

        &:focus
            color: #495057
            border-color: #005CA9
            border-width: 2px
            outline: 0
            box-shadow: none

        &-required
            color: red
        &-container
            display: flex
            flex-direction: column

        &-password
            font-size: 1.5rem
            color: $colorGrey600
            cursor: pointer
            position: absolute
            top: 6px
            right: 8px
            &:hover,
            &:focus
                color: $colorPrimary500
            &:active
                color: $colorPrimary500

        &--correct
            border-color: #449D44

        &--delete
            font-size: 0.875rem
            &-container
                display: flex
                justify-content: space-between

    &__textarea
        &-row
            position: relative
            margin-left: 0
            margin-right: 0
            height: 180px

    &__select
        &-row
            position: relative
            margin-left: 0
            margin-right: 0

    &__checkbox
        accent-color: $colorPrimary500
        display: inline-block
        width: auto
        height: auto
        margin-right: 10px

    &__error
        position: relative
        color: $colorError600
        padding: 16px 40px
        margin-bottom: 40px
        background: #FCF6F5
        border: 1px solid #F2CAC8
        border-radius: 5px

    &__message
        &--info
            color: $colorGrey700
            font-size: 1rem
            margin-top: 6px
            +mobile
                font-size: 0.8125rem

        &--invalid
            color: $colorError600
            flex-basis: 100%
            +mobile
                font-size: 0.8125rem

label
    margin-bottom: 5px
    font-size: 0.875rem

input::placeholder
    font-size: 1rem
