@import "@Styles/_breakpoints.sass"
@import "@Styles/_text.sass"
@import "@Styles/_font-colors.sass"

@import "@Styles/_colors.sass"

$columns-desktop-readonly: 55px 300px 140px 1fr
$columns-touch-readonly: 50px 150px 140px 1fr

$columns-desktop: append($columns-desktop-readonly, fit-content(300px))
$columns-touch: append($columns-touch-readonly, fit-content(300px))
$columns-mobile: 1fr

.layers
	&__container
		color: $colorBlack

	&__separator
		width: 100%
		border-bottom: 1px solid $colorGrey200
		max-height: 1px
		margin-bottom: 1rem

	&__breadcrumbs
		margin: 0px 20px

	&__title
		margin: 2rem 0 1.5rem

	&__status
		margin-top: 1.5rem
		margin-bottom: 2rem
		max-width: 750px
		p
			margin: 0 0 0.5rem

		span
			color: $colorMidnightBlue500

	&__table
		margin-bottom: 2rem
		&--header
			background-color: $colorGrey200
			height: 54px
			display: flex
			align-items: center
			font-size: 1.125rem
			font-weight: 700
			padding: 0 0.5rem

		+mobile
			background-color: $colorGrey200
			margin-left: -1rem
			margin-right: -1rem
			padding: 1rem

		&--caption
			background-color: $colorPacificBlue600
			color: $colorWhite
			+mobile
				display: none !important

		&--row
			padding: 0
			margin-bottom: 0.5rem
			height: 56px
			max-height: 56px
			display: grid
			grid-template-columns: $columns-desktop
			+touch
				grid-template-columns: $columns-touch
			+mobile
				height: auto
				max-height: unset
				grid-template-columns: $columns-mobile

				background-color: $colorWhite
				border: 1px solid $colorGrey600
				border-radius: 5px
				padding: 1rem
				row-gap: 1rem
				margin-bottom: 1.5rem

		&--column
			border: 1px solid $colorGrey200
			padding: 0 15px
			display: flex
			align-items: center
			gap: 30px
			&:not(:last-child)
				border-right: none

			+mobile
				border: none
				padding: 0
				gap: 0

		&--bt-column
			@extend .layers__table--column
			+mobile
				flex-direction: column-reverse
				background: $colorGrey200
				padding: 1.5rem
				margin: 0 -1rem -1rem
				gap: 1rem
				border-radius: 0px 0 5px 5px

		&--row__readOnly
			@extend .layers__table--row
			display: grid
			grid-template-columns: $columns-desktop-readonly
			+touch
				grid-template-columns: $columns-touch-readonly
			+mobile
				grid-template-columns: $columns-mobile

		&--mobile-label
			display: none
			+mobile
				display: block

		&--mobile-description
			flex: 0 0 100px
			display: none !important
			color: $colorGrey800
			+mobile
				display: flex !important

	&__new-layer-bt
		margin: 1rem 0
		+mobile
			width: 100%

.layer-edit-bt
	padding: 0.5rem
	+mobile
		align-self: stretch

.layer-delete-bt
	text-decoration: none
	color: $colorMidnightBlue500
	&:hover
		text-decoration: underline
