@import "@Styles/_font-colors.sass"
@import "@Styles/_breakpoints.sass"
@import "@Styles/_colors.sass"

.footer
    background: $colorGrey300
    font-size: 1rem
    margin-top: 0
    color: $colorMidnightBlue500
    display: flex
    justify-content: space-between
    padding: 0.5rem 2rem
    gap: 0
    +touch
        border: 1px solid blue
        padding: 1rem
        flex-direction: column
        align-items: center
        gap: 1rem

    +mobile-small
        align-items: flex-start
        padding-left: 2rem
        gap: 2rem

    &__block
        line-height: 1.25rem
        padding: 1rem 0
        color: $colorMidnightBlue500
        display: flex
        gap: 5px
        & span
            color: $colorBlack

        i
            font-size: 0.875rem

        +touch
            padding: 0

        +mobile-small
            flex-direction: column
            gap: 10px

        &--sparse
            @extend .footer__block            
            gap: 20px
            +widescreen
                gap: 30px

