@import "@Styles/_colors.sass"
@import "@Styles/_text.sass"
@import '@Styles/_breakpoints.sass'

.events
    &__header
        margin-bottom: 24px
        margin-top: 48px
        &__inner
            display: flex
            justify-content: space-between
            align-items: center
            border-bottom: 1px solid $colorGrey200
            padding-bottom: 32px

        @include desktop
            margin-bottom: 72px

    &__title
        @extend .text--h2
        margin-bottom: 0

    &__tiles
        &__title
            margin: 72px 0
            @extend .text--h3
            &--pagination
                @extend .text--h3
                margin: 72px 0 32px

    &__description
        @extend .text--body
        max-width: 700px
        margin-bottom: 24px
        margin-top: 24px
        @include desktop
            margin-top: 24px
            margin-bottom: 0
    &__select-container
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: column
        margin-bottom: 16px
        @include desktop
            flex-direction: row
            margin-bottom: 0

    &__select
        background-color: #FFFFFF
        height: 3rem
        border-radius: 5px
        border: 1px solid #999DA1
        color: #010913
        width: 100%
        padding: 0 8px
