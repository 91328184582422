@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

$eu-logo: url("~@Assets/images/eu_logo.svg")

.top-bar
    color: $colorWhite
    padding: 0
    display: block
    +touch
        background-color: $colorWhite
        position: absolute
        top: 0
        left: 0
        width: 100vw
        margin: 0
        max-height: calc(100vh - 126px)
        overflow-y: auto

    &__ue-logo
        height: 30px
        width: 45px
        background-image: $eu-logo
        background-size: cover
        position: relative
        margin-left: 120px
        &::before
            content: "Unia Europejska"
            position: absolute
            color: white
            width: 100px
            font-size: 14px
            top: 5px
            left: -110px

    &-dropdown
        &__button
            font-size: 1rem
            height: auto
            width: auto
            border: none
            padding: 0
            margin: 0
            font-weight: 400
            line-height: 20px
            color: $colorMidnightBlue300
            display: flex
            align-items: center

            &::after
                font-family: 'fontello'
                content: '\e81a' // chevron-down
                color: $colorMidnightBlue300
                border: none
                font-size: 20px
                margin-right: -7px

            &:hover, &:focus:not(:focus-visible)
                background-color: transparent
                color: $colorWhite
                &::after
                    color: $colorWhite

            &:focus, &:active
                background: transparent
                color: $colorMidnightBlue300
                &::after
                    color: $colorMidnightBlue300

            &[aria-expanded="true"]
                &::after
                    transform: rotate(180deg)

            +touch
                display: flex
                justify-content: space-between
                align-items: center
                width: 100%
                height: 38px
                font-size: 1rem
                background-color: transparent
                padding-left: 0
                margin-left: 0
                color: $colorMidnightBlue500
                font-size: 0.875rem

                &::after
                    color: $colorMidnightBlue500

                &:hover, &:focus:not(:focus-visible)
                    background-color: transparent
                    color: $colorMidnightBlue700
                    &::after
                        color: $colorMidnightBlue700

                &[aria-expanded="true"]
                    &::after
                        transform: rotate(180deg)

            &:focus
                outline: none

        &__menu
            border-radius: 0px 0px 5px 5px
            box-shadow: 0px 3px 6px 0px #00000029
            background-color: $colorMidnightBlue700
            +touch
                background-color: $colorWhite

        &__item
            color: $colorMidnightBlue300 !important
            padding: 0.5rem 2rem 0.5rem 1rem !important
            font-size: 0.875rem
            &:hover
                color: $colorBlack !important

            +touch
                color: $colorMidnightBlue500 !important

    &__collapsed
        +touch
            display: none

    &__row
        display: flex
        justify-content: flex-end
        align-items: center
        padding: 12px 0
        gap: 20px
        +touch
            justify-content: space-between
            padding: 0 1rem
        +widescreen
            gap: 40px

        &--menu
            font-size: 1.1rem
            padding: 12px 0
            align-items: center
            justify-content: end
            gap: 36px
            +touch
                flex-direction: column
                align-items: start
                margin: 0
                gap: 0
                \:global(.dropdown)
                    width: 100%
                > div
                    width: 100%
                    padding-top: 12px
                    padding-bottom: 12px
    &__item
        padding: 0px 4px
        color: #005CA9
        text-decoration: none
        +desktop
            color: #005CA9
            &:hover,
            &:focus,
            &:active
                color: #005CA9
        +touch
            font-size: 1rem

        &--access
            display: flex
            line-height: 30px
            gap: 13px
            +touch
                border-left: none
                width: 100%

        &--lang
            +touch
                width: 100%

    &__mobile-header
        font-size: 1.25rem
        height: 82px
        display: flex
        justify-content: space-between
        align-items: center
        color: $colorBlack
        padding: 0 32px
        +mobile
            padding: 0 16px
        h2
            font-size: inherit
        &--close-bt
            font-size: 1.75rem
            color: $colorMidnightBlue500
            cursor: pointer
            &:hover
                color: $colorBlack

.dropdown
    &:hover,
    &:focus
        text-decoration: underline

    &__wrapper
        margin-bottom: 20px
        &:last-child
            margin-bottom: 10px
    &__button
        background-color: $colorWhite
        font-size: 1.0625rem
        height: auto
        width: auto
        border: none
        border-radius: 0px
        padding: 2px 2px
        margin: 0px 10px
        line-height: 20px
        color: #005CA9
        border-bottom: 1.5px solid transparent
        +touch
            padding-left: 0
            margin-left: 0
        &::after
            margin-left: 14px

        &:hover,
        &:focus
            border-color: #005CA9
            color: #005CA9
            background: #FFFFFF
            border-bottom: 1.5px solid #005CA9

        &[aria-expanded="true"]
            background-color: #FFFFFF
            color: #005CA9
            &::after
                transform: rotate(180deg)

        +touch
            display: flex
            justify-content: space-between
            align-items: center
            width: 100%
            height: 38px
            font-size: 1rem
            color: #000000
            &::after
                color: #404244

            &:hover
                background-color: #F8F8F8
                border-color: #D3D3D3
                color: #373A3C

            &[aria-expanded="true"]
                background-color: #F8F8F8
                border-color: #D3D3D3

        &:focus
            outline: none

        &--divider
            padding: 0
            width: 75%
            margin: 0.25rem 1.5rem
            border-top: 1px dotted #C0C0C0
        &--disabled
            &-black
                color: black !important
            &-grey
                color: #4A4A4A !important

    &__menu
        margin-top: 5px
        left: auto
        right: 0
        min-width: 90px
        font-size: 0.875rem
        border: 1px solid #DDDDDD
        border-radius: 0
        padding: 0
        z-index: 1022

        +touch
            background-color: #FFF
            border-color: #DDD

            &::before
                border-bottom-color: #DDD

            &::after
                border-bottom-color: #FFF

    &__item
        color: #373A3C
        font-size: 0.875rem
        height: 48px
        padding-left: 35px
        display: flex
        align-items: center
        border-bottom: 1px solid #DDDDDD
        background-color: #FFFFFF
        border-top-right-radius: 5px
        border-top-left-radius: 5px

        &:last-child
            border-bottom: none
            border-radius: 0
            border-bottom-left-radius: 5px
            border-bottom-right-radius: 5px

        &:hover
            color: #FFF
            background-color: #1A73E8
            +touch
                background-color: #fff

        &:active
            background-color: #F4F4F4
            color: #373A3C

        &--not-active
            color: #005CA9

        i
            position: absolute
            left: 13px

.mobile-top-bar
    &--divider
        border-top: dotted 1px #C0C0C0

    &--label
        font-size: 1em
        margin: 15px 0px 5px 0px

.custom-card
    background: $colorGrey100
    border-left: none
    border-right: none
    margin-bottom: 5px
    border: none
    padding: 0
    border-radius: 5px !important

.custom-card-header
    background-color: $colorGrey200
    font-weight: 400
    font-size: 1rem
    border: none !important
    color: $colorBlack
    & button
        &:hover, &:focus
            text-decoration: none
            color: $colorPrimary500
    & i
        font-size: 22px
        top: 7px

.custom-card-body
    padding: 8px 0

.custom-card-item
    color: $colorBlack
    font-size: 0.875rem
    border: 1px
    display: block
    padding: 1rem
    &:hover
        text-decoration: none
        color: $colorPrimary500
        background-color: $colorMidnightBlue200

    &__logout
        display: flex
        justify-content: flex-end
        margin: 1rem

.card-header-toggle
    text-decoration: none

    i
        color: $colorPrimary500
        position: absolute
        right: 10px
        top: 7px

    &--active
        @extend .card-header-toggle
        font-weight: 700

.login-element
    margin-top: 1rem
    padding-top: 2rem !important
    border-top: 1px solid $colorGrey300

.create-account-wrapper
    display: flex
    gap: 15px
    align-items: center
    justify-content: flex-start
    margin-top: 1.25rem
    & p
        margin: 0
        color: $colorBlack
        min-width: fit-content
    & button
        font-size: 0.875rem
        text-decoration: none
        color: $colorPrimary500
