:root {
    --spacing : 1.5rem;
    --radius  : 10px;
  }
  
  
    .tree li {
      margin       : 10px 0px;
      display      : block;
      position     : relative;
      padding-left : calc(2 * var(--spacing) - var(--radius) - 2px);
    }
  
    .tree ul{
      margin-left  : calc(var(--radius) - var(--spacing));
      padding-left : 0;
    }
  
    .tree ul li{
      border-left : 2px solid #ddd;
    }
  
    .tree ul li:last-child{
      border-color : transparent;
    }
  
    .tree ul li::before{
      content      : '';
      display      : block;
      position     : absolute;
      top          : calc(var(--spacing) / -2);
      left         : -2px;
      width        : calc(var(--spacing) + 2px);
      height       : calc(var(--spacing) + 1px);
      border       : solid #ddd;
      border-width : 0 0 2px 2px;
    }
  
    .tree ul li:first-child::before{
      border-width : 0 0 2px 2px;
    }

    .tree a {
        margin-left: -12px
    }

    .tree .selected-page {
        margin-left: -12px
    }
  
    .tree li::after {
      content       : '';
      display       : block;
      position      : absolute;
      top           : calc(var(--spacing) / 2 - var(--radius));
      left          : calc(var(--spacing) - var(--radius) - 1px);
      width         : 0.7rem;
      height        : 1.25rem;
      border: none;
      background    : #F5F5F5;
    }
    
    .tree ul::before {
      margin-left: 0.2rem;
      content: "";
      background: transparent;
    }
  