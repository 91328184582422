@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

.transcr-item
    width: 100%
    background: $colorGrey200
    display: flex
    flex-direction: column
    align-items: center
    max-width: 100vw
    overflow: hidden
    margin-bottom: 10px

    &__button
        background-color: transparent
        padding: 11.25px 30px
        font-size: 1.0625rem
        +mobile
            width: 100%

    &__description
        width: 100%
        font-size: 0.875rem
        display: flex
        flex-direction: column
        background-color: $colorGrey300
        padding: 1rem 0

        & span[class*="text__blue"]
            color: $colorMidnightBlue500

        +touch
            font-size: 0.6875rem
            grid-template-columns: 1fr

        &--matches
            &-bold
                font-weight: bold

        &--left-left
            display: flex
            flex-direction: row
            gap: 15px

        &--left-wrapper
            width: 100%
            padding: 16px 0
            background-color: $colorPacificBlue600
            color: $colorGrey100
            font-size: 1rem
            min-height: 64px

            +touch
                grid-template-columns: auto
                grid-row-gap: 8px

        &--container
            display: flex
            justify-content: space-between
            align-items: center
            gap: 15px
            +touch
                flex-direction: column
                align-items: center
                gap: 5px

        &--metadata
            display: flex
            justify-content: space-between
            padding: 0
            font-size: 1rem
            +touch
                flex-direction: column
                padding-top: 20px
                padding-bottom: 20px
                gap: 20px
            &-left
                display: flex
                gap: 20px
                & :global(> div)
                    +touch
                        padding-top: 1px
                        padding-bottom: 1px
                +touch
                    flex-direction: column
                    gap: 18px

            &-right
                +touch
                    padding-top: 1px
                    padding-bottom: 1px

        &--addInfo
            margin-top: 20px
            display: flex
            font-size: 1rem
            justify-content: space-between
            +touch
                flex-direction: row
                align-items: flex-start

            &-inner
                display: flex
                flex-direction: row
                justify-content: space-between
                width: 100%
                gap: 10px
                +touch
                    flex-direction: column

            &-left
                display: flex
                gap: 1rem

            &-layers
                display: flex
                align-items: center

            &-status
                background-color: $colorPacificBlue200
                color: $colorPacificBlue700
                padding: 0.5rem 1rem
                border-radius: 5px
                &--failed
                    @extend .transcr-item__description--addInfo-status
                    background-color: $colorError200
                    color: $colorError600

        &--separator
            display: block
            border-left: 1px solid #C0C0C0
            margin: 0
            +touch
                display: none

        &--progress
            grid-column: span 2
            display: flex
            justify-content: space-between
            +touch
                grid-column: 1
                flex-direction: column
                align-items: flex-start
                margin-top: 5px

        &--progress-wrapper
            display: flex
            align-items: center
            margin-left: auto
            +touch
                flex-wrap: wrap
                justify-content: flex-start
                width: 250px
                gap: 10px

        &--progress-bar
            width: 150px
            margin: 0 10px
            display: flex
            align-items: center
            +touch
                margin: 0

        &--progress-text
            font-size: 1rem
            color: #373A3C

        &--label
            font-weight: bold
            margin-right: 30px
            +touch
                margin-right: 0
                margin-bottom: 5px

        &--icon
            color: #555858
            &::before
                margin-left: -0.2em

        &--title
            font-size: 1.25rem
            line-height: 1.3
            font-weight: 700
            color: $colorGrey100
            +touch
                text-align: center

            &:hover, &:focus, &:active
                text-decoration: underline
                color: $colorWhite

        &--date
            font-weight: 700

        &--author
            font-weight: normal

        &--checkbox
            width: 18px
            height: 18px
            cursor: pointer
            margin-top: 7px

            &:hover,
            &:focus
                box-shadow: 0px 0px 5px 5px rgb(255, 255, 255)

    &__pages
        width: 100%
        padding-top: 30px
        display: grid
        grid-gap: 10px
        grid-auto-flow: column
        grid-auto-columns: 280px
        overflow-x: auto
        padding-bottom: 1rem
        +desktop
            grid-auto-flow: unset
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))
            padding-bottom: 0

        &--disabled
            @extend .transcr-item__pages
            pointer-events: none
            opacity: 0.5

    &__footer
        width: 100%
        display: flex
        justify-content: center
        position: relative
        margin: 20px 0 20px
        @media screen and (max-width: 530px)
            max-width: 400px
            flex-direction: column-reverse

        &__first
            margin-right: auto
            width: 85px

        &__show-all
            align-self: center
            margin-left: auto
            width: 85px
            font-size: 1.0625rem
            color: #005CA9
            @media screen and (max-width: 530px)
                margin-left: 0
                height: 50px

            &:hover
                text-decoration: underline
                cursor: pointer
    &__added-to-event
        height: 120px
        display: flex
        justify-content: center
        align-items: center
        border-radius: 0px 0px 4px 4px
        border: 1px solid $colorPacificBlue600
        width: 100%
        gap: 10px
        &__text
            color: $colorPacificBlue600
            font-size: 1.5rem
            line-height: 150%
            letter-spacing: -0.24px
            margin-bottom: 0

.transcription
    &__page
        &--box
            border-radius: 4px
            position: relative
            height: min-content
            transition: box-shadow 0.3s ease

            &:hover,
            &:focus
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)

        &--block
            &-bottom
                background-color: $colorWhite
                padding: 10px 12px
                border-radius: 0 0 4px 4px

        &--image
            overflow: hidden
            position: relative
            width: 100%
            background-size: cover
            background-position: center
            border-radius: 4px 4px 0 0
            height: 201px
            background-color: #55595C
            +desktop
                height: 201px
            +fullhd
                height: 200px

            img
                display: none

            &-error
                @extend .transcription__page--image
                background-size: 80px
                background-repeat: no-repeat

        &--number
            color: $colorBlack
            font-weight: 300
            font-size: 0.875rem
            margin-bottom: 9px
            display: block
            text-align: right
            +widescreen
                margin-bottom: 13px
            &:hover,
            &:focus
                color: $colorPrimary500

        &--progress
            display: flex
            flex-direction: column
            margin-bottom: 8px
            &-text
                color: $colorBlack
                font-size: 1rem
                &-short
                    display: none
                    +touch
                        display: block
                &-layer
                    display: block
                    color: $colorMidnightBlue500
                &-long
                    font-size: 0.875rem

            &-bar
                display: flex
                align-items: center
                &--wrapper
                    height: 10px
                    background-color: $colorPacificBlue200
                    box-shadow: 0px 2px 8px 0px #4747471F inset
                    border-radius: 6px
                    display: flex
                    margin-right: 14px
                    flex: 1
                    +touch
                        margin-right: 6px
                &--meter
                    background-color: $colorPacificBlue600
                    display: flex
                    margin: 1px
                    border-radius: 6px
                &--active
                    @extend .transcription__page--progress-bar--meter
                    background-color: $colorPacificBlue600
            &-value
                color: $colorBlack
                font-size: 0.6875rem

        &--ribbon
            display: block
            background-color: #FFF
            font-size: 0.6875rem
            color: #555858
            position: absolute
            font-weight: 700
            right: -25px
            padding: 4px
            width: 100px
            text-align: center
            transform: rotate(45deg)
            top: 12px

        &--span
            display: none

        &__magicBox
            height: 4rem
            overflow: hidden
            text-align: center
            padding-top: 15px
            +desktop
                display: none

            & button
                width: calc(100% - 4px)

.navigation
    position: absolute
    top: 0
    right: 0
    z-index: 999
    &__checkbox
        display: none

    //&:has(&__checkbox:checked)
      //  width: 100%

    &__checkbox:checked ~ &__nav
        height: auto
        min-width: fit-content
        display: block
        float: right

    &__button
        background-color: $colorGrey100
        width: 55px
        height: 55px
        margin-bottom: 0
        display: block
        margin-left: auto
        cursor: pointer
        text-align: center
        
    &__nav
        display: none
        background-color: $colorGrey100
        border-radius: 0 0 5px 5px
        box-shadow: 0px 3px 6px 0px #00000029

    &__list
        list-style: none
        margin: 0
        padding: 8px 0

    &__item
        color: $colorBlack
        padding: 8px 18px
        font-size: 0.875rem
        cursor: pointer

        &:hover
            background-color: $colorPrimary100
            color: $colorMidnightBlue500
        
        & a
            color: inherit

        & a:hover
            text-decoration: none

    &__icon
        margin-top: 25px
        position: relative
        &,
        &::before,
        &::after
            content: ''
            width: 4px
            height: 4px
            display: inline-block
            background-color: $colorMidnightBlue500
        &::before,
        &::after
            position: absolute
            left: 0
        &::before
            top: -10px
        &::after
            top: 10px
