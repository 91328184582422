@import "@Styles/_breakpoints.sass"
@import "@Styles/_colors.sass"

.lang
    &__select
        &--wrapper
            color: $colorMidnightBlue300
            display: flex
            gap: 10px

        &--button
            cursor: pointer
            &:hover
                    color: $colorWhite
            &--active
                @extend .lang__select--button
                font-weight: 600
            i
                &::before
                    margin-left: -3px

.lang-dropdown
    &__button
        display: flex
        justify-content: space-between
        align-items: center
        width: 68px
        height: 38px
        font-size: 0.875rem
        background-color: $colorWhite
        width: 68px
        padding: 2px 15px 2px 10px
        line-height: 20px
        color: $colorBlack
        border: none
        border-bottom: 1px solid $colorPrimary500
        border-radius: 0
        &::after
            font-family: 'fontello'
            content: '\e81a'
            color: $colorPrimary500
            border: none
            font-size: 25px
            font-style: normal

        &:hover,
        &:focus
            outline: none
            background-color: $colorMidnightBlue200
            color: $colorBlack

        &[aria-expanded="true"]
            &::after
                transform: rotate(180deg)
           

    &__menu
        margin-top: 5px
        left: 0
        right: 0
        top: -5px
        max-width: 68px
        min-width: unset
        z-index: 1022
        border-radius: 0 0 5px 5px
        box-shadow: 0px 3px 6px 0px #00000029

    &__item
        color: $colorPrimary500
        
        font-size: 0.875rem
        height: 38px
        max-width: 68px
        padding-left: 30px
        display: flex
        align-items: center
        background-color: $colorWhite

        &--not-active
            color: $colorBlack !important

        i
            position: absolute
            left: 8px
            color: $colorPrimary500
