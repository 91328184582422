@import "@Styles/_colors.sass"
@import "@Styles/_text.sass"
@import '@Styles/_breakpoints.sass'

.event-pages
    &__title
        @extend .text--h2
    &__subtitle
        @extend .text--body
    &__back
        margin-bottom: 32px
    &__description
        display: flex
        flex-direction: column
        gap: 8px
        align-items: flex-start
        p
            margin-bottom: 0
        @include desktop
            flex-direction: row
            margin-bottom: 56px
            align-items: center
            gap: 24px
    &__row
        display: grid
        gap: 10px
        grid-template-columns: repeat(auto-fill, minmax(100px, 278px))
    &__pages
        background-color: $colorGrey200
        padding: 16px 0
        @include desktop
            padding: 42px 0
    &__buttons
        display: flex
        gap: 5px
        overflow-x: auto
    &__button-transcription
        background: $colorWhite
        color: $colorBlack
        text-align: center
        font-family: Roboto, sans-serif
        font-size: 18px
        font-style: normal
        font-weight: 500
        line-height: 150%
        letter-spacing: -0.18px
        border-radius: 0
        border-bottom: 3px solid transparent
        max-width: 250px
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        flex: 0 0 auto
        margin: 3px 0

        &:hover, &:active, &:focus
            background: $colorWhite
            color: $colorBlack
            border-bottom: 3px solid $colorPacificBlue600
        &--active
            border-bottom: 3px solid $colorPacificBlue600
    &__item
        position: relative
    &__mask
        position: absolute
        width: 100%
        height: 60%
        z-index: 2
    &__add
        position: relative
        display: flex
        width: auto
        gap: 10px
        padding: 10px 20px
        background-color: rgba(255, 255, 255, 0.8)
        min-height: 44px
        input
            cursor: pointer
    &__added-mask
        display: flex
        align-items: center
        background-color: rgba(255, 255, 255, 0.8)
        height: 70%
        width: 100%
        top: -44px
        position: relative
        margin-top: 44px
        justify-content: center

        &__text
            color: $colorPacificBlue600
            text-align: center
    &__added-mask-inner
        margin-top: -44px
        display: flex
        flex-direction: column
        align-items: center
        gap: 10px

    &__select
        max-width: 185px
    &__buttons-assign-hide
        display: flex
        flex-direction: column
        gap: 20px

        @include desktop
            flex-direction: row
            gap: 48px
            align-items: center

    &__buttons-assigne-all
        font-size: 0.875rem !important
        background: transparent
        @include desktop
            font-size: 1rem !important

    &__hide
        display: flex
        gap: 12px
        align-items: center
    &__hide-checkbox
        width: 18px
        height: 18px
        cursor: pointer

        &:hover,
        &:focus
            box-shadow: 0px 0px 5px 5px rgb(255, 255, 255)

    &__hide-label
        cursor: pointer
        font-size: 14px
        font-style: normal
        font-weight: 400
        margin-bottom: 0
