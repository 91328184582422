@import '@Styles/_font-colors.sass'
@import '@Styles/_colors'

=dropdown-hover
	border-color: #80bdff
	outline: 0
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)

.transcription
	&__tooltip
		i
			color: $colorPrimary500
			&:hover, &focus
				color: $colorBlack

	&-import
		&__description
			margin: 1rem 0 2rem
		&__row
			&--select
				max-width: 33%
				min-width: 280px
				+mobile
					max-width: none
		&__sources
			text-align: right
		&__doubts
			&--wrapper
				text-align: center
				+mobile
					background-color: $colorGrey200
					border-radius: 5px
					padding: 1rem
					& button
						margin-top: 0
		&__after-import-comment
			margin-top: 1rem

	&-new
		&__main
			font-size: 1rem
			color: $colorBlack
			line-height: 1.5rem

			p
				font-size: 1rem
				line-height: 1.5rem

			h2
				font-size: 1.625rem
				font-weight: 700
				+mobile
					font-size: 1.4375rem

			h3
				font-size: 1.4375rem
				font-weight: 700
				margin-bottom: 1rem
				+mobile
					font-size: 1.25rem

			h4
				color: $colorGrey800
				font-size: 1.125rem
				font-weight: 700
				margin-bottom: 0
				text-transform: uppercase
				margin-top: 3rem
				+mobile
					font-size: 1.125rem

			label
				font-size: 1rem
			label:not([for])
				font-size: 0.875rem

			+mobile
				max-width: unset
				padding: 0

		&__block
			max-width: 1220px
			background-color: $colorGrey100
			padding: 3rem
			margin-left: auto
			margin-right: auto
			display: flex
			flex-direction: column
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12)
			margin-bottom: 4rem
			+mobile
				padding: 1rem
				box-shadow: none

		&__header
			&--container
				display: flex
				justify-content: space-between
				align-items: center
				+mobile
					flex-direction: column-reverse
					align-items: flex-start

		&__description
			margin-top: 2rem

		&__paragraph
			max-width: 650px

		&__import-btn
			+widescreen
				max-width: fit-content

		&__required-info
			color: #525252
			margin-top: 30px
			width: 20rem

		&__form
			&--textarea-wrapper
				margin-top: 20px
				& textarea
					background-color: $colorWhite
					padding: 1rem
					border-radius: 5px
					border: 1px solid $colorGrey600
					color: $colorBlack

			&-required
				color: red

			&--row
				&-full
					flex: 0 1 100%
				&-narrow
					flex: 0 1 calc(33.6% - 20px)
					&:first-child
						flex-basis: 33%

					+mobile
						flex: 0 1 calc(50% - 20px)
						&:first-child
							flex-basis: calc(50% - 20px)

				&-half
					flex: 0 1 50%

			&--links
				display: grid
				grid-auto-flow: row
				row-gap: 10px

			

			&--inline
				display: flex
				margin-top: 20px
				gap: 20px
				+mobile-small
					flex-direction: column

				&--identifiers
					margin-top: 20px

				&-select
					flex: 0 1 33%

					& .dropdown-common
						height: 3rem

						& .dropdown-toggle
							height: 100%
							background-color: $colorWhite
							padding: 1rem
							border: 1px solid $colorGrey600
							&:active,&:focus,&:hover
								+dropdown-hover

							&::after
								font-family: 'fontello'
								content: '\e81a'
								color: $colorPrimary500
								border: none
								font-size: 25px
								font-style: normal

					+mobile-small
						flex: 1
					.css-tlfecz-indicatorContainer
						padding: 100px

				&-full
					flex: 1
				&-list
					padding: 0
					margin: 0
					list-style: none
				&-item
					margin-top: 15px

			&--label
				font-size: 0.875rem
			&--terms
				margin-top: 50px

				.btn
					color: $colorPrimary500
					text-decoration: none

					&:hover
						color: $colorPrimary500
			&--btns
				display: flex
				justify-content: flex-end
				margin-top: 50px
				align-items: center
				gap: 1rem
				+mobile
					flex-direction: column-reverse
					align-items: stretch
					& button
						width: 100%

		&__btn
			&--add
				font-size: 0.875rem !important
				margin-top: 10px
			&--remove
				font-size: 0.875rem

.no-column-mobile
	+mobile-small
		flex-direction: row
		flex-wrap: wrap
