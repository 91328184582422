@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

.verification
    margin-top: 40px

    &__status
        margin-top: 10px
        margin-bottom: 30px
        &--value
            color: $colorMidnightBlue500

    &__pagination
        display: flex
        justify-content: flex-end
        margin: 1.5rem 0
        grid-gap: 0

        +mobile
            justify-content: center

        +widescreen
            position: absolute
            right: 16px
            margin: 0
            background: transparent
            top: 66px

            button[class*="pagination__btn--next"]
            padding-right: 0
            &::after
                content: '\e820'
                font-family: 'fontello'
                right: 0
                margin-left: 5px
                position: relative
                border: none
                top: 0

            button[class*="pagination__btn--prev"]
                padding-right: 0
                &::before
                    content: '\e81b'
                    font-family: 'fontello'
                    left: 0
                    margin-right: 5px
                    position: relative
                    border: none
                    top: 0

        div[class*="pagination-top--left"]
                    margin-left: 0
                    margin-right: 0

        &--wrapper
            position: relative
            margin-bottom: 20px
            >div[class*="pagination-bottom"]
                top: auto
                bottom: 8px
                display: grid
                column-gap: 15px
                +touch-sec
                    display: none !important

            +mobile
                background-color: $colorGrey100
                margin: 0 -15px 20px
                padding: 0 1rem 1rem

    &__title
        font-size: 1.625rem
        font-weight: 700
        margin: 0

        &--container
            display: flex
            justify-content: space-between
            align-items: center
            border-bottom: 1px solid #DDDDDD
            padding-bottom: 10px
            +mobile
                flex-direction: column
                align-items: flex-start
    &__stats
        &-link
            font-size: 0.875rem
    &__info
        color: #4A4A4A
        font-size: 0.875rem
        &--container
            display: flex
            align-items: center
            margin-bottom: 5px
            &:last-child
                margin-bottom: 0
        &--label
            font-size: 0.875rem
            margin-right: 10px
        &--author
            font-size: 0.875rem

        &--title
            font-size: 1rem
            font-weight: 700
            i
                color: #555858

    &__advancement
        font-size: 1rem
        +mobile
            background-color: $colorGrey100
            margin: 0 -15px
            padding: 2rem 1rem 1rem

        &--header
            display: flex
            justify-content: space-between
            margin-bottom: 2rem

        &--expand-bt
            display: none
            color: $colorMidnightBlue500
            text-decoration: none
            +mobile
                display: block

        &--hide-on-mobile
            +mobile
                display: none

        &--title
            font-size: 1.25rem
            font-weight: 700
            +mobile
                font-size: 1.125rem

        &--item
            display: flex
            margin-bottom: 1rem
            flex-wrap: wrap

        &--layer-name
            font-weight: 700
            display: flex
            flex-basis: 100%
            align-items: center
            margin-bottom: 16px
            +tablet
                margin-bottom: 0
            +widescreen
                flex-basis: 120px
                margin-bottom: 0

        &--element
            display: flex
            justify-content: center
            align-items: center
            +touch
                justify-content: flex-start
                flex-basis: 50%
                margin-bottom: 10px
            +mobile
                flex-basis: 100%
                margin-bottom: 10px

        &--label
            color: $colorBlack
            width: 140px
            text-align: right
            padding-right: 8px
            +mobile
                padding-left: 16px
                width: 180px
                text-align: left

        &--value
            background-color: $colorPacificBlue600
            color: $colorWhite
            border-radius: 0
            display: flex
            justify-content: center
            align-items: center
            font-weight: 700
            width: 92px
            height: 2rem

        &--divider
            border-top: 1px solid $colorGrey300
            margin: 2rem 0

    &__dropdown
        display: none
        +touch
            display: block
        button[class*="btn-dropdown"]
            height: 4rem
            background-color: $colorGrey200
            font-weight: 700
            border: none
            padding: 0 1rem
            &::after
                border: none
                content: '\e81a' // chevron-down
                font-family: 'fontello'
                color: $colorPrimary500
                font-size: 25px

            &[aria-expanded="true"]
                &::after
                    content: '\e821' // chevron-down

        div[class*="dropdown-menu"]
                    width: calc(100% - 2rem)

    &__table
        &--header
            padding: 1rem
            background-color: $colorGrey200
            color: $colorGrey900
            font-size: 1.25rem
            font-weight: 700
            max-height: 56px
            border: 1px solid $colorWhite
            border-bottom: none
            border-top: none
            +touch
                display: none

            &--touch
                display: none
                font-size: 1.125rem
                font-weight: 700
                margin-top: 1.5rem
                +touch
                    display: block

        &--caption
            border: none
            min-height: 56px
            display: none
            font-size: 0.75rem
            background-color: $colorPacificBlue600
            color: $colorWhite

            &--item
                border: 1px solid $colorGrey300
                border-right: none
                padding: 16px
                font-size: 0.875rem
                &:last-child
                    border-left: none
                    border-right: 1px solid $colorGrey300
                span
                    border-bottom: 1px solid $colorWhite

            +touch-sec
                display: none !important

            &-verification
                padding-left: 20px
            &-lp
                flex: 0 1

        &--footer
            height: 56px
            margin-top: 5px
            background-color: $colorGrey200
            +touch-sec
                display: none

        &--row
            display: grid
            +widescreen
                grid-template-columns: 85px 120px 120px 120px 120px 120px 1fr 260px
                font-size: 0.875rem
            +hd
                grid-template-columns: 85px 170px 170px 170px 170px 170px 1fr 260px
                font-size: 1rem
            +fullhd
                font-size: 1rem
                grid-template-columns: 85px 180px 180px 180px 180px 180px 1fr 280px

        &--content
            padding: 0
            margin: 15px 0 40px
            +touch-sec
                margin-top: 40px
        &--col-desc
            display: inline-block
            flex: 0 1 180px
            +widescreen
                display: none

        &--item
            border: 1px solid $colorGrey200
            background: $colorGrey100
            margin-top: 2px
            border-radius: 0
            color: $colorGrey800
            padding: 0
            height: 56px
            +touch-sec
                // smaller than 1200
                border: 1px solid $colorGrey600
                background: $colorWhite
                margin-top: 4px
                height: auto
                padding: 1rem
                border-radius: 4px

            & > div:not(&__buttons)
                display: flex
                align-items: center
                justify-content: flex-end
                border-right: 1px solid $colorGrey200
                padding: 0 1rem

                +touch-sec
                    // smaller than 1200
                    justify-content: flex-start
                    align-items: flex-start
                    padding: 0
                    padding-bottom: 1rem
                    display: flex

            & > div[class*="right-column-mod"]
                justify-content: flex-start
                padding-left: 35px
                +touch-sec
                    padding: 0
                    padding-bottom: 1rem

            &__buttons
                display: flex
                justify-content: center
                align-items: center
                padding: 0 10px
                & a
                    display: none

                +touch-sec
                    display: block
                    margin: 0 -16px -16px
                    padding: 16px
                    border-radius: 0px 0px 4px 4px
                    background: $colorGrey100
                    & a
                        display: block
                    & button
                        display: none

            &__button
                display: flex
                height: 44px
                width: 100%
                justify-content: center
                align-items: center
                text-decoration: none
                color: $colorMidnightBlue500
                i
                    margin-right: 10px
                    font-size: 13px

            &-label
                display: none
                font-size: 0.75rem
                color: #555858
                margin-right: 10px
                +touch-sec
                    display: flex
                    flex: 0 1 100px
                +mobile
                    flex: 1

            &-lp
                font-size: 0.75rem
                padding: 15px 12px 0
                color: #555858
                font-weight: 700
                +widescreen
                    font-size: 0.875rem
                    padding: 15px 12px 15px 20px
            &-img
                background-color: #55595C
                height: 45px
                width: 52px
                border-radius: 4px
                display: flex
                align-items: center
                justify-content: center
                background-size: cover
                i
                    display: none
                    font-size: 2rem
                    color: #696D6F
                img
                    display: none
                &-error
                    i
                        display: block

                &-container
                    padding-top: 15px
                    flex: 0 1 50px
                    +touch-sec
                        flex: 1
                        padding: 15px 12px 0
            &-name
                color: #1C7CD5
                font-size: 0.875rem
                font-weight: 700
                flex: 1
                word-wrap: break-word
                +hd-only
                    width: 100px

                &-container
                    flex: 1
                    display: flex
                    &::before
                        content: none
            &-edition
                display: flex
                padding-bottom: 15px
                +touch-sec
                    flex: 1
                +widescreen
                    flex: 0 1 220px
                    min-height: 70px
                +hd
                    flex: 0 1 220px
                +fullhd
                    flex: 0 1 220px
                &-container
                    display: flex
                    flex-direction: column
                &-email
                    font-size: 0.875rem
                &-date
                    font-size: 0.75rem
                    color: #555858
            &-verification
                background-color: #FFF
                color: #217ACC
                text-align: center
                padding-bottom: 15px
                display: flex
                align-items: center
                justify-content: center
                border-radius: 0 4px 4px 0

                +touch-sec
                    border-top: 1px solid #DDDDDD
                    border-radius: 0 0 4px 4px
                &:hover,
                &:focus
                    text-decoration: none
                    background-color: #217ACC
                    color: #FFF
                &:active
                    background-color: #0E60CB
                +touch-sec
                    flex: 1
                +widescreen
                    flex: 0 1 120px
                +hd
                    flex: 0 1 180px
                +fullhd
                    flex: 0 1 275px
                &::before
                    height: 100%
                    top: 0

.tabs
    display: flex
    gap: 0
    margin-bottom: 5px
    +touch
        display: none

    &__item
        padding: 13px 32px
        background-color: $colorWhite
        border-bottom: 3px solid transparent
        color: $colorBlack
        font-weight: 700
        text-decoration: none !important
        &:hover
            color: $colorPacificBlue600

        &--active
            border-bottom: 3px solid $colorPacificBlue600
            text-decoration: none !important
            pointer-events: none
