$viewerColorWhite: #FFFFFF
$viewerColorBlack: #010913

//Primary
$viewerColorPrimary100: #d5e4ec
$viewerColorPrimary200: #99C8E4
$viewerColorPrimary300: #66ACD7
$viewerColorPrimary500: #0075BC
$viewerColorPrimary600: #005E96
$viewerColorPrimary700: #004671

//MidnightBlue  
$viewerColorMidnightBlue200: #E5EDF5
$viewerColorMidnightBlue300: #AFC8DF
$viewerColorMidnightBlue500: #2467A6
$viewerColorMidnightBlue700: #0D253C

//PacificBlue 
$viewerColorPacificBlue200: #C0E4EC
$viewerColorPacificBlue500: #209FBB
$viewerColorPacificBlue600: #187A8F
$viewerColorPacificBlue700: #115463

//Grey
$viewerColorGrey100: #FAFAFA
$viewerColorGrey200: #F2F2F3
$viewerColorGrey300: #E6E7E8
$viewerColorGrey400: #CCCED0
$viewerColorGrey500: #B3B6B9
$viewerColorGrey600: #999DA1
$viewerColorGrey700: #676B71
$viewerColorGrey800: #343A42
$viewerColorGrey900: #1B222B

//Consistency errors
$viewerColorConsistencyError: #E99758

//Search
$viewerColorSearchLine: #999DA1
$viewerColorSearchFlag: #C09223

//Annotation
$viewerColorAnnotation100: #00FF7F
$viewerColorAnnotation500: #01954A

//Music
$viewerColorMusic: #0F8670