@import '@Styles/_breakpoints.sass'

$logo-big: url("~@Assets/images/wlt_logo.svg")
$logo-big-hover: url("~@Assets/images/wlt_logo.svg")
$logo-big-active: url("~@Assets/images/wlt_logo.svg")

.auth-header
    width: 100%
    border-bottom: 1px solid #DDDDDD

    &__logo
        height: 86px
        width: 275px
        margin: 45px 0 40px -2px
        background-image: $logo-big
        background-size: cover
        filter: brightness(0)
        text-indent: -9999rem
        &:hover,
        &:focus
            background-image: $logo-big-hover
            filter: brightness(50%)
        &:active
            background-image: $logo-big-active
            filter: none
        +touch
            height: 46px
            width: 147px
            margin: 18px 0
