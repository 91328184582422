@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

.transcription
    color: $colorBlack

    &__main-container
        display: flex
        flex-direction: column

    &__header
        margin-top: 2rem
        margin-bottom: 1rem

    &__section
        margin-top: 0px
        &--border
            border-bottom: 2px solid #DDDDDD
    &__section:last-child
        margin-bottom: 2rem

    &__title--wrapper
        display: flex
        justify-content: space-between
        align-items: flex-start

    &__metadata
        display: flex
        justify-content: space-between
        margin: 2rem 0
        padding-bottom: 2rem
        border-bottom: 1px solid $colorGrey200
        +touch
            flex-direction: column
            margin: 1rem 0
            padding-bottom: 1rem

        >h3
            margin: 0
            font-size: 1.4375rem
            font-weight: 700
            +touch
                font-size: 1.25rem

    &__main
        &--collapsed
            display: none

        &--button-wrapper
            justify-content: flex-end
            display: flex
            position: relative

            &--button-collapse
                color: #005CA9
                text-decoration: none
                border: none
                appearance: none
                transition: none
                display: flex
                flex-wrap: nowrap
                font-size: 0.8rem
                outline: 0 !important
                outline-color: white !important
                &:hover
                    color: #005CA9
                    border: none
                    outline: 0 !important
                    outline-color: white !important
                &:focus
                    box-shadow: none !important

    &__alert
        &--margin-bottom
            margin-bottom: 45px

    &__meta
        display: flex
        align-items: baseline
        gap: 15px
        margin-bottom: 0.5rem

        &--value
            color: $colorPrimary500

        &--details-wrapper
            flex: 0 1 225px
            display: flex
            flex-wrap: wrap
            justify-content: flex-start
            margin-bottom: 0.5rem

            +touch
                flex-direction: column
                gap: 0px
                margin-bottom: 10px
            &-first
                margin-top: -45px

            &--click-to-copy
                text-decoration: none
                color: #1A73E8
                cursor: pointer

                &:hover
                    text-decoration: underline

            &--title
                font-size: 1rem
                color: #404040
                padding-right: 0.5rem
            &--break
                flex-basis: 100%
                width: 0
                height: 0
            &--rich
                & > *
                    margin-bottom: 0
            &--rich
                p
                    font-size: 1rem

        &--collapsed
            margin-top: -44px
            display: flex
            flex-direction: row
            margin-bottom: 16px
            +desktop-only
                padding-right: 200px

            +touch
                flex-direction: column
                margin-top: 12px
        &--title
            font-size: 1.25rem
            font-weight: bold
            flex: 1
            margin-bottom: 0
        &--name
            flex: 0 1 225px
            clear: both
            font-size: 1rem
            font-weight: bold
            margin-bottom: 0
            +touch
                flex: 1 1
        &--value
            flex: 1
            font-size: 1rem
            margin-bottom: 0
        &--links
            flex: 1
            font-size: 1rem
            &-container
                @extend .transcription__meta--value
                display: flex
                gap: 10px
                // flex-direction: column
                .transcription__meta--links
                    line-height: 2rem
            i
                vertical-align: top
                font-size: 0.625rem

    &__description
        margin: 1.2rem 0 0.5rem
        color: $colorGrey800
        &--title
            font-weight: 700

    &__additionalText
        font-size: 0.875rem
        text-align: right
        &--gray
            color: #555858
        &--box
            margin-top: 24px
            margin-bottom: 26px
    &__link
        padding: 7px 25px
        &--active
            @extend .transcription__link
            box-shadow: inset 0px 4px 0 0px #1C7CD5
            background-color: #FCFCFC
    &__backLink
        font-size: 0.875rem
        margin-bottom: 34px

    &__desktopNav
        display: flex
        justify-content: flex-end
        +touch
            display: none

        >div
            gap: 10px

        & button, & a
            margin-right: 0 !important

    &__mobileNav
        display: none
        +touch
            display: block
            text-align: right
        &--dropdown
            margin-bottom: 27px
        &--dropdown-menu
            text-align: center
            border-radius: 0 0 5px 5px
            box-shadow: 0px 4px 4px 0px #00000040
            // margin-top: -32px !important
            a
                font-size: 0.875rem
                color: $colorBlack
                padding: 10px

        &--additional-button
            @extend .transcription__link

.pages-list
    padding-top: 40px

    & *[class*="pagination-top--container"]
        margin-bottom: 40px

    &__wrapper
        background-color: $colorGrey100
        flex: 1

    &__head
        margin-bottom: 12px
        +fullhd
            display: flex
            align-items: baseline
            justify-content: flex-end
        &-column
            display: flex
            align-items: flex-start
            justify-content: flex-start
            gap: 20px
            margin-top: 2rem
            +touch-sec
                flex-direction: column

        & button[class*="btn"], a[class*="btn"]
            +desktop-only
                padding: 15px

    &__header
        font-size: 1.4375rem
        font-weight: bold
        color: $colorBlack
        margin-bottom: 0
        +fullhd
            margin-bottom: 0
        &--thin
            font-weight: normal
        &--thick
            font-weight: normal
            display: flex
            justify-content: space-between

            +touch
                flex-direction: column

            &--item
                display: flex

                &-progress
                    @extend .pages-list__header--thick--item
                    gap: 15px
                    +mobile
                        flex-direction: column
                        gap: 0
                    +touch-sec
                        width: 100%
                    >div:nth-child(2)
                        flex: 1


                &--key
                    font-weight: normal

                &--value
                    font-weight: bold
                    display: flex
                    align-items: center
                    gap: 8px

                &--progress-bar
                    width: 100%
                    min-width: 150px
                    display: flex
                    align-items: center
            

    >div[class*="pagination-top"]
        +hd
            margin-top: -42px

    &__modification-date
        width: 100%
        font-size: 0.875rem
        text-align: right
        margin-top: 47px

    &__grid
        width: 100%
        padding: 30px 0
        display: grid
        grid-column-gap: 10px
        grid-row-gap: 30px
        justify-content: space-between
        grid-template-columns: repeat(1, 1fr )

        @media screen and (min-width: 420px)
            grid-template-columns: repeat( 2, 1fr )

        +tablet
            grid-template-columns: repeat( 3, minmax(150px, 1fr) )

        +desktop
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr))

    &__no-page
        margin: 70px 0
        text-align: center

.navigation
    position: absolute
    top: 0
    right: 0
    z-index: 999

    &__dropdwn
        >button[class*="dropdown-toggle"]
            background: $colorGrey200
            color: $colorBlack !important
            border: none
            width: 100%
            display: flex
            justify-content: space-between
            align-items: center
            padding: 1rem
            &:active
                background: $colorGrey100 !important

            &::after
                border: none
                content: '\e81a' // chevron-down
                font-family: 'fontello'
                color: $colorPrimary500
                font-size: 28px

            &[aria-expanded="true"]
                font-weight: 700
                background-color: $colorGrey200
                &::after
                    content: '\e821'

        & div[class*="dropdown-menu"]
            width: 100%
            text-align: left

        & *[class*="dropdown-item"]
            padding: 1rem
            font-size: 0.875rem
            &[aria-disabled="true"]
                color: $colorGrey600

    &__container
        display: none
        +touch
            display: block

    &__toggle
        width: 20px
        height: 25px
        display: flex
        justify-content: flex-end
        cursor: pointer
        background: none
        padding: 0
        &::after
            content: none

        &:active, &:hover, &:focus
            background: none !important
            box-shadow: none !important
            border: none !important

    &__checkbox
        display: none

    &:has(&__checkbox:checked)
        width: 100%

    &__checkbox:checked ~ &__nav
        opacity: 1
        height: auto
        min-width: fit-content

    &__nav
        float: right
        opacity: 0
        width: 0
        height: 0
        background-color: $colorGrey100
        border-radius: 0 0 5px 5px
        box-shadow: 0px 3px 6px 0px #00000029

    &__list
        list-style: none
        margin: 0
        padding: 8px 0

    &__item
        color: $colorBlack
        padding: 8px 18px
        font-size: 0.875rem
        cursor: pointer

        &:hover
            background-color: $colorPrimary100
            color: $colorMidnightBlue500

        & a
            color: inherit

        & a:hover
            text-decoration: none

    &__icon
        position: relative
        top: 10px
        &,
        &::before,
        &::after
            content: ''
            width: 4px
            height: 4px
            display: inline-block
            background-color: $colorMidnightBlue500
        &::before,
        &::after
            position: absolute
            left: 0
        &::before
            top: -10px
        &::after
            top: 10px

.manifest
    &__image
        height: 1rem
        margin-top: -0.25rem
        margin-right: 0.5rem
