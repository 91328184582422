@import "@Styles/_colors.sass"
@import "@Styles/_text.sass"
@import '@Styles/_breakpoints.sass'

.events
    border-bottom: 1px solid $colorGrey200
    padding-bottom: 24px
    @include desktop
        padding-bottom: 32px

    &__header
        margin-bottom: 24px
        margin-top: 48px

        &__inner
            display: flex
            justify-content: space-between
            align-items: center
        @include desktop
            margin-bottom: 32px

    &__transcriptions-added
        display: flex
        gap: 8px
        flex-direction: column
        padding-bottom: 24px
        &__item
            padding: 16px 40px
            background-color: $colorGrey200
            display: flex
            flex-direction: column
            gap: 16px
            justify-content: space-between
            @include desktop
                flex-direction: row
        &__title
            @extend .text--h4
        &__button-delete
            padding: 0
            background-color: $colorGrey200
            border: 1px solid transparent
            display: flex
            gap: 8px
            align-items: center
            align-self: flex-end
    &__transcriptions-zero
        padding: 48px 0
        display: flex
        align-items: center
        justify-content: center
        &__text
            @extend .text--h2
            font-weight: 500
    &__projects
        &__header

            margin: 24px 0
        &__title
            @extend .text--h2
            margin-bottom: 32px
        &__description
            max-width: 680px
            @extend .text--body
            margin-bottom: 40px
    &__search
        display: flex
        gap: 20px
        flex-direction: column
        position: relative
        z-index: 2
        @include desktop
            position: relative
            bottom: -34px
            max-width: 100%

            @include desktop
                max-width: 452px
            @include widescreen
                max-width: 705px

        &__input
            max-width: 510px
            width: 100%
            outline: none
            border: 1px solid transparent
            border-bottom: 1px solid $colorGrey600
            padding: 14px 10px
            max-width: 500px
            &:focus
                border: 1px solid $colorGrey500
        &__selects
            display: flex
            flex-direction: column
            gap: 20px
            @include widescreen
                flex-direction: row
