@import "@Styles/_breakpoints.sass"
@import "@Styles/_colors.sass"

.pagination-top
    position: relative
    display: grid
    grid-auto-flow: column
    align-items: center
    grid-auto-columns: min-content
    grid-gap: 15px
    flex-wrap: wrap

    +mobile
        grid-auto-flow: row

    &__anchor
        position: absolute

    +mobile-micro
        font-size: 0.875rem
    &--perpage
        white-space: nowrap
    &--left
        display: grid
        grid-auto-flow: column
        align-items: center
        grid-auto-columns: auto
        grid-gap: 15px
        margin-left: auto
        margin-right: auto
        @include desktop
            margin-left: auto
            margin-right: unset

    &--perpage
        white-space: nowrap

    &--container
        display: flex
        justify-content: space-between
        margin-bottom: 50px
        +touch
            margin-top: 2rem
        +mobile
            margin-right: auto

    &__item
        display: flex
        align-items: center
        gap: 20px

    &__items
        display: flex
        flex-wrap: wrap
        gap: 20px

.pagination__btn
    background: transparent
    border: none
    border-radius: 4px
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: 400
    color: $colorPrimary500
    height: 40px
    position: relative
    font-size: 1rem
    padding: 0

    &:hover, &:active
        background: transparent
        border: none
        color: $colorPrimary700
    &:focus, &:disabled
        box-shadow: none

    +mobile
        font-size: 0px
        padding: 0
        width: 40px
    +mobile-micro
        width: 30px
        height: 30px

    &--next
        padding-right: 0
        &::after
            content: '\e820'
            font-family: 'fontello'
            font-size: 20px
            margin-left: 5px

    &--prev
        padding-right: 0
        &::before
            content: '\e81b'
            font-family: 'fontello'
            font-size: 20px
            margin-right: 5px

.pagination-bottom
    display: flex
    align-items: center
    gap: 15px
    justify-content: flex-end
    padding: 1.5rem 0
    +mobile
        justify-content: center
    +mobile-micro
        gap: 10px
        font-size: 0.875rem
        justify-content: space-between

    &__list
        display: grid
        grid-auto-flow: column
        grid-gap: 15px
        align-items: center
        margin: 0
        padding: 0

    &__item
        list-style-type: none
        color: #135FC3
        text-decoration: none
        background-color: transparent
        cursor: pointer
        margin: 0
        padding: 0

        &:hover
            text-decoration: none
            color: #373A3C

        &--disabled
            color: #373A3C
            text-decoration: none
            background-color: transparent
            margin: 0
            padding: 0
            list-style-type: none
            pointer-events: none
    &__spacer
        padding-bottom: 100px

.pagination__input
    text-align: center
    border: none
    border-bottom: 1px solid $colorGrey600
    color: $colorGrey900
    min-width: 35px
    max-width: 55px
    background: transparent

    &:focus
        border: 1px solid $colorGrey600
