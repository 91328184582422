@import "@Styles/_colors.sass"
@import '@Styles/_breakpoints.sass'
@import "@Styles/_text.sass"

.tile-event, .tile-event-row
    &__img
        width: 100%
        min-height: 280px
        background-size: cover
        margin-bottom: 8px
        background-position: center
        @include desktop
            width: 100%
            max-width: 430px
            min-height: 290px
            margin-bottom: 0px

    &__content
        display: flex
        flex-direction: column
        justify-content: space-between
        width: 100%
        padding: 8px

        &__title
            font-family: 'Roboto', sans-serif
            font-style: normal
            font-weight: 700
            font-size: 18px
            line-height: 140%
            @include desktop
                font-family: 'Roboto', sans-serif
                font-style: normal
                font-weight: 700
                font-size: 20px
                line-height: 150%
        &__dates
            display: flex
            flex-direction: row
            gap: 11px

        &__calendar
            width: 18px
            height: 20px

        &__date
            display: flex
            flex-direction: column
            gap: 5px
            @include desktop
                flex-direction: row
            span
                font-family: 'Roboto', sans-serif
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 150%
                @include desktop
                    font-family: 'Roboto', sans-serif
                    font-style: normal
                    font-weight: 700
                    font-size: 18px
                    line-height: 120%

        &__description
            @extend .text--body
            @include desktop
                display: block
        &__top
            display: flex
            justify-content: space-between
            flex-direction: column
            margin-bottom: 16px
            @include desktop
                flex-direction: row

    &__attend
        background: $colorWhite
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12)
        border-radius: 4px
        height: 40px
        width: 135px
        display: flex
        justify-content: center
        align-items: center

        span
            @extend .text--body
            font-weight: 500
            line-height: 40px

    &__button-container
        display: flex
        justify-content: flex-end
        button
            width: 100%
            @include desktop
                width: unset

.tile-event
    display: flex
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25)
    background-color: $colorGrey100
    flex-direction: column
    max-width: 300px
    width: 100%
    margin: 0 auto

    @include desktop
        flex-direction: row
        padding: 0
        max-width: 100%

    &__container
        display: flex
        flex-direction: column
        gap: 32px
        padding: 0

    &__content
        @include desktop
            padding: 24px 40px

.tile-event-row
    display: flex
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25)
    background-color: $colorGrey100
    flex-direction: column
    max-width: 300px
    width: 100%

    @include desktop
        max-width: 415px

    &__img
        min-height: 290px
        background-repeat: no-repeat
        background-size: contain
        @include desktop
            min-height: 440px

    &__container
        justify-content: center
        margin: 0 auto
        display: flex
        flex-direction: row
        gap: 20px
        flex-wrap: wrap
        @include desktop
            justify-content: left

    &__button-container
        justify-content: center

    &__content
        height: 100%
        @include desktop
            padding: 24px 40px
        &__description
            display: none
