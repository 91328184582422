@import "@Styles/_breakpoints.sass"
@import "@Styles/_colors.sass"

=btn-lg
    padding: 19px 38px
    font-size: 1.375rem
=btn-med
    padding: 15px 30px
    font-size: 1rem
=btn-sm
    padding: 12px 24px
    font-size: 0.875rem

.btn
    border-radius: 5px
    line-height: 1
    +btn-med
    &.btn-lg
        +btn-lg
    &.btn-sm
        +btn-sm

    &:hover, &:focus, &:active
        box-shadow: none

    &:not(:disabled):not(.disabled):active:focus
        box-shadow: none

    &-override
        vertical-align: inherit
        border: 0
        line-height: inherit
        border-radius: 0
        font-weight: inherit
        text-align: inherit

    &-primary
        background-color: $colorPrimary500
        border: 0
        color: $colorWhite

        &:hover, &:focus
            background: $colorPrimary600
            color: $colorWhite

        &:focus
            box-shadow: 0 0 0 0.15rem rgba(49,132,253,.5)

        &:not(:disabled):not(.disabled):active
            background: $colorPrimary700
            color: $colorWhite
            box-shadow: 0 0 0 0.15rem rgba(49,132,253,.5)

        &.disabled,
        &:disabled
            pointer-events: none
            background: $colorGrey200
            color: $colorGrey700
            border: 1px solid $colorGrey600

    &-secondary
        background-color: transparent
        border: 1px solid $colorPrimary500
        color: $colorPrimary500
        box-shadow: none

        &:hover, &:focus
            background-color: transparent
            color: $colorPrimary600
            border: 1px solid $colorPrimary600

        &:focus
            box-shadow: 0 0 0 0.15rem rgba(49,132,253,.5)

        &:not(:disabled):not(.disabled):active
            background-color: $colorPrimary300
            color: $colorPrimary700
            border: 1px solid $colorPrimary700
            box-shadow: none

        &:not(:disabled):not(.disabled):active:focus
            box-shadow: none

        &[disabled],
        &:disabled,
        &.disabled
            color: $colorGrey700
            background: $colorWhite
            pointer-events: none
            border: 1px solid $colorGrey600

    &-tertiary
        color: $colorPrimary500
        background-color: transparent
        border: 1px solid transparent

        &:hover, &:focus
            background-color: transparent
            color: $colorPrimary600
            border: 1px solid $colorPrimary200

        &:focus
            box-shadow: 0 0 0 0.15rem rgba(49,132,253,.5)

        &:not(:disabled):not(.disabled):active
            background-color: transparent
            color: $colorPrimary700
            border: 1px solid $colorPrimary300

        &[disabled],
        &:disabled,
        &.disabled
            color: $colorGrey700
            background: $colorWhite
            pointer-events: none
            border: 1px solid $colorWhite

    &-text
        padding: 0
        color: $colorPrimary500
        border: none

        &:hover
            background-color: transparent
            text-decoration: underline
            color: $colorPrimary500

        &:focus
            background-color: transparent
            text-decoration: none
            color: $colorPrimary600
            box-shadow: 0 0 0 0.15rem rgba(49,132,253,.5)

        &:not(:disabled):not(.disabled):active
            background-color: transparent
            text-decoration: none
            color: $colorPrimary700

        &[disabled],
        &:disabled,
        &.disabled
            color: $colorGrey700
            background: $colorWhite
            pointer-events: none
            border: 1px solid $colorWhite

    &-third
        background: #C9C9C9
        border-color: #C9C9C9
        color: #373A3C

        &:hover, &:focus
            background: #b7b7b7
            border-color: #b7b7b7
            color: #373A3C
    &-editQuota
        @extend .btn-secondary
        width: 28px
        height: 28px
        border-radius: 0px
        &:hover, &:focus
            border-color: #0E60CB

    &-quotaAccept
        @extend .btn-primary
        width: 28px
        height: 28px
        border-radius: 0px

    &-cancel
        background: #e6e6e6
        border-color: #C9C9C9
        color: #373A3C
        padding: 0 6px
        font-size: 1.4rem

    &-primary, &-secondary
        &.icon
            padding: 0
            width: 55px
            i
                font-size: 1.3rem

            &-xl
                i
                    font-size: 1.5rem
    &-editQuota, &-quotaAccept
        &.icon
            padding: 0
            width: 28px
            margin-right: 10px
            i
                font-size: 1.3rem
                display: flex
                align-items: center
                justify-content: center

    &-external
        display: block
        color: #373A3C
        background-color: #E6E6E6
        border-color: #D5D5D5
        width: 100%
        padding: 11px

        &:hover,
        &:focus
            color: #FFF
            background-color: #1A73E8
            border-color: #D5D5D5

        &:active
            color: #FFF
            background-color: #0E60CB
            border-color: #D5D5D5

        i
            font-size: 0.8rem
            line-height: 0.7rem
            vertical-align: text-top

    &-submit
        @extend .btn-primary
        padding: 10px
        min-width: 215px
        font-size: 1.062rem

    &-link
        @extend .btn-override

        background-color: transparent
        color: #373A3C
        padding: 0
        font-size: inherit
        text-decoration: underline
        &:hover
            text-decoration: underline

    &-link-external
        @extend .btn-link

        i
            font-size: 0.65rem
            line-height: 0.7rem
            vertical-align: text-top

    &-link-onblack
        @extend .btn-link

        color: #FFFFFF

        &:hover
            color: #CBCBCB

        &:active
            color: #329AFC

    &-link-external-onblack
        @extend .btn-link-external

        color: #FFFFFF

        &:hover
            color: #CBCBCB

        &:active
            color: #329AFC

    &-icon
        @extend .btn-override
        padding: 0

    &-dropdown
        background: #FFF
        border-color: #DDDDDD
        color: #373A3C
        &:hover,
        &:focus,
        &:active
            background: #F4F4F4
            border-color: #DDDDDD
            color: #373A3C

    &-dropdown-secondary
        @extend .btn-dropdown
        width: 100%
        border-top: 4px solid #1C7CD5
        display: flex
        justify-content: space-between
        margin-top: 28px
        &::after
            display: none

    &-download
        &:hover,
        &:focus
            background: #1A73E8
            color: #FFF
        &:active
            background: #0E60CB
            color: #FFF

.show
    .btn-dropdown
        background: #F4F4F4
        border-color: #DDDDDD
        color: #373A3C

    .btn-viewer-button
        background: #1E2026
        color: #DDDDDD
        text-decoration: none
        box-shadow: none
