$colorWhite: #FFFFFF
$colorBlack: #010913

//Primary
$colorPrimary100: #d5e4ec
$colorPrimary200: #99C8E4
$colorPrimary300: #66ACD7
$colorPrimary500: #0075BC
$colorPrimary600: #005E96
$colorPrimary700: #004671

//MidnightBlue  
$colorMidnightBlue200: #E5EDF5
$colorMidnightBlue300: #AFC8DF
$colorMidnightBlue500: #2467A6
$colorMidnightBlue700: #0D253C

//PacificBlue 
$colorPacificBlue200: #C0E4EC
$colorPacificBlue500: #209FBB
$colorPacificBlue600: #187A8F
$colorPacificBlue700: #115463

//Grey
$colorGrey100: #FAFAFA
$colorGrey200: #F2F2F3
$colorGrey300: #E6E7E8
$colorGrey400: #CCCED0
$colorGrey500: #B3B6B9
$colorGrey600: #999DA1
$colorGrey700: #676B71
$colorGrey800: #343A42
$colorGrey900: #1B222B

//Success
$colorSuccess200: #9FCFC6
$colorSuccess500: #0F8670
$colorSuccess600: #0C6B5A

//Annotation
$colorAnnotation100: #00FF7F
$colorAnnotation500: #01954A

//Error
$colorError200: #FFCDCD
$colorError500: #E11717
$colorError600: #AC1111

//Warning
$colorWarning400: #E99351
$colorWarning500: #E37826

//Yellow
$colorYellow500: #FCBF2D
$colorYellow600: #C09223
$colorYellow700: #87691E
