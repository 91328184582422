@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

.progress__bar
    &--wrapper
        height: 10px
        background-color: $colorPacificBlue200
        border-radius: 6px
        display: flex
        flex: 1

    &--meter
        background-color: $colorPacificBlue600
        display: flex
        margin: 1px
        border-radius: 6px

    &--active
        display: flex
        margin: 1px
        border-radius: 6px
        background-color: #5CB95C
