@import "@Styles/_breakpoints.sass"
@import "@Styles/_mixins.sass"
@import "@Styles/_text.sass"
@import "@Styles/_font-colors.sass"
@import "@Styles/_colors.sass"

$mod1Img: url('~@Assets/images/financing_european_funds.svg')
// $mod2Img: url('~@Assets/images/uwr_logo_blue.svg')
$mod2Img: url('~@Assets/images/financial/financial-2.svg')
$mod3Img: url('~@Assets/images/financing_eu_logo.svg')

.financing
    background: $colorWhite
    border-top: 1px solid $colorGrey300
    margin-top: 32px
    +desktop
        margin-top: 64px

    &__img
        width: 427px
        height: 96px
        background-position: center
        background-repeat: no-repeat

    &__img_1
        @extend .financing__img
        background-image: $mod1Img

    &__img_2
        @extend .financing__img
        width: 340px
        height: 78px
        background-size: cover
        background-image: $mod2Img

    &__img_3
        @extend .financing__img
        width: 352px
        height: 78px
        background-size: cover
        background-image: $mod3Img

    &__container
        padding: 32px 16px
        +desktop
            padding: 64px 16px

    &__content
        margin-top: 60px

    &__footer
        display: grid
        grid-gap: 50px
        justify-content: center
        align-items: center
        transform: scale(0.7)
        margin: -60px 0
        +desktop
            margin: 0
            grid-template-columns: repeat(3, auto)
        +fullhd
            grid-gap: 100px
            transform: none

    &__text
        &--header
            text-align: center

        &--content
            font-size: 1rem
            line-height: 1.625rem
            text-align: center
            max-width: 800px
            margin: 0 auto
            +touch
                font-size: 0.875rem
