@import "@Styles/_breakpoints.sass"
@import "@Styles/_colors.sass"
@import "@Pages/Transcription/TranscriptionVerification/TranscriptionVerification.module.sass"

.indexes
    padding: 35px 0 60px
    &__go-back
        color: #161615
        text-decoration: underline
        font-size: 0.875rem

    &__title
        border-bottom: 1px solid $colorGrey200
        padding-bottom: 1rem
        margin-bottom: 1.5rem
        color: $colorBlack
           
    &__info
        color: $colorMidnightBlue500
        &--container
            margin-top: 1rem
        &--label
            color: $colorGrey800
            margin-right: 5px

    &__filters
        margin-top: 10px
        display: flex
        justify-content: center
        align-items: center
        &--label
            font-size: 1rem
            margin-right: 6px
            +desktop
                display: none

        &--dropdown-alphabet
            width: 50px
            margin-right: 10px
            display: inline-block
            margin-top: -7px
            +desktop
                margin-top: 0

        &--dropdown-pages
            width: 100px
            margin-right: 10px
            display: inline-block
            margin-top: -7px
            +desktop
                margin-top: 0

    &__header
        display: none
        height: 50px
        width: 100%
        background-color: $colorPacificBlue600
        margin-top: 2rem
        color: white
        align-items: center
        font-weight: 700
        +desktop
            display: flex

        &--mobile
            padding: 1.5rem 1rem 0
            +desktop
                display: none

            >h3
                font-weight: 700
                margin-bottom: 0

        >div
            height: 100%
            display: flex
            align-items: center
            padding: 0 1rem
            border-right: 1px solid $colorWhite
            &:first-child
                flex: 0 1 300px
            &:last-child
                border-right: none

    &__dropdown
        display: block
        border: 1px solid $colorGrey600
        border-radius: 5px
        height: 44px
        +desktop
            display: none
        button[class*="btn-dropdown"]
            background-color: $colorWhite
            font-size: 0.875rem
            border: none
            height: 100%
            padding: 0 1rem
            &::after
                border: none
                content: '\e81a' // chevron-down
                font-family: 'fontello'
                color: $colorPrimary500
                font-size: 25px

            &[aria-expanded="true"]
                &::after
                    content: '\e821' // chevron-down



        div[class*="dropdown-menu"]
            width: 100%
            max-width: 300px

    &__table
        display: flex
        margin: 2rem -15px
        flex-direction: column
        background: $colorGrey100
        +desktop
            margin: 0 0 8px
            flex-direction: row
            background-color: transparent

        &--search-input
            margin-bottom: 30px
            display: flex
            background-color: $colorWhite
            border: 1px solid $colorGrey600
            height: 44px
            border-radius: 5px
            padding: 0 1rem
            +desktop
                max-width: 500px
                border-radius: 0px
                border: 0px
                background-color: transparent
                border-bottom: 2px solid $colorGrey300
                height: auto

            & input
                background: transparent
                border: none
                flex: 1
                +desktop
                    width: 450px
                    padding: 6px 10px 6px 15px

                &::-webkit-input-placeholder
                    font-style: normal

                &:focus-visible
                    border: none
                    outline: none

            & i
                color: $colorGrey700
                margin: auto

        &--aside
            display: flex
            flex-direction: column
            padding: 1rem
            color: $colorBlack
            gap: 1rem
            +desktop
                flex: 0 1 300px
                background-color: $colorWhite
                border: 1px solid $colorGrey300
                padding: 1.25rem
                gap: 0

            &-element
                display: flex
                align-items: center

                & button[class*="btn-dropdown"]
                    &::after
                        border: none
                        content: '\e81a' // chevron-down
                        font-family: 'fontello'
                        color: $colorPrimary500
                        font-size: 16px
                        font-weight: 700

                +desktop
                    flex-direction: column
                    padding: 10px 5px
                    width: 160px
                    margin-bottom: 1rem

                &:first-child
                    padding-bottom: 1rem
                    border-bottom: 1px solid $colorGrey300
                    align-items: stretch
                    display: none
                    +desktop
                        display: flex

                & .tag-type
                    border-radius: 5px
                    padding: 1rem
                    background-color: transparent
                    cursor: pointer                   

                    &-active
                        background-color: $colorPacificBlue200
                        font-weight: 700
                        pointer-events: none
                        
                    &:hover
                        text-decoration: underline

                    &--count
                        font-weight: normal
                        color: black

        &--content
            flex: 1
            background-color: $colorGrey100 
            padding: 1rem           
            
            +desktop
                border: 1px solid $colorGrey300
                border-left: none
                padding: 42px 24px 24px

            &-indicator
                display: flex
                &-letter
                    font-size: 1rem
                    width: 40px
                    height: 40px
                    display: flex
                    justify-content: center
                    align-items: center
                    font-weight: 500
                    color: white
                    background-color: $colorPacificBlue600

                &-line
                    flex-grow: 1
                    border-bottom: 1px solid $colorGrey300
                    padding: 0.4rem 0.3rem
                    +mobile
                        margin-left: 0

        &--item
            border: 3px solid red
            background-color: #F4F4F4
            margin-bottom: 6px
            border-radius: 4px
            display: grid
            grid-template-columns: 1fr 215px 280px 320px
            +touch-sec
                display: flex
                flex-direction: column
            &-label
                display: none
                font-size: 0.75rem
                border: 1px solid red
                color: #555858
                margin-right: 10px
                +touch-sec
                    display: flex
                    flex: 0 1 240px
                +mobile
                    flex: 1

            &-default
                padding: 15px 12px 0
                position: relative
                font-size: 0.75rem
                +touch-sec
                    flex: 1
                    display: flex
                    flex-direction: row
                    align-items: baseline
                    &:last-child
                        padding-bottom: 15px
                +mobile
                    flex-direction: column

                +widescreen
                    padding: 15px 12px
                    font-size: 0.875rem
                +widescreen
                    &::before
                        content: ''
                        width: 1px
                        height: calc(100% - 30px)
                        display: block
                        background: #DDDDDD
                        position: absolute
                        left: 0
                        padding: 15px 0
                        top: 15px
            &-name
                @extend .indexes__table--item-default
                display: flex
                &::before
                    display: none
                &-container
                    display: flex
                    flex-direction: column
                &-email
                    font-size: 0.9375rem
                    font-weight: 700
                &-contact
                    font-size: 0.875rem
                    margin-top: 5px
                    i::before
                        margin-left: 0

    &__no-stats
        font-size: 0.875rem
        line-height: 1.5rem
        margin-top: 40px

    &__list
        list-style: none
        padding: 5px 0
        &--item
            display: flex
            margin-top: 12px
            position: relative
            flex-direction: column
            +desktop
                flex-direction: row
                

        &--item-name
            width: 160px
            font-weight: 500

        &--item-collapsebt
            position: absolute
            right: 0
            color: $colorMidnightBlue500
            cursor: pointer            
            &:hover
                text-decoration: underline
            +desktop
                display: none

        &--item-pages
            padding-left: 26px
            display: flex
            width: 100%
            flex-flow: row wrap
            p
                margin-bottom: 3px
            +touch
                padding-left: 0

        &--item-link
            margin-right: 10px
            color: $colorMidnightBlue500
            &-hideonmobile
                @extend .indexes__list--item-link
                +touch
                    display: none

        &--item-count
            color: black
