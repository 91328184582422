@import "@Styles/_breakpoints.sass"
@import "@Styles/_text.sass"

.form
    margin-top: 0

    &--block
        margin-top: 1.5rem

    &--info
        padding-top: 2rem
        font-size: 0.875rem
