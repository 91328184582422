@import '@Styles/_colors.sass'

.trainOCR
    &__radio-input
        display: flex
        align-items: center
        gap: 8px
        margin-bottom: 8px
        margin-bottom: 8px
        cursor: pointer

        & label
            margin: 0
            cursor: pointer

        input[type=radio]
            accent-color: $colorBlack
            cursor: pointer

    &__range-group
        margin-left: 20px

    

.modal__notice
    color: $colorGrey700
    font-size: 1rem
    margin-top: 5px