@import "@Styles/_colors.sass"
@import "@Styles/_text.sass"
@import '@Styles/_breakpoints.sass'

.form
    max-width: 976px
    width: 100%
    background: $colorGrey100
    padding: 0px
    margin: 48px auto
    display: flex
    flex-direction: column
    gap: 8px
    @include desktop
        padding: 48px
    &__error
        @extend .text--body
        color: $colorError500

    &__title
        @extend .text--h2
    &__subtitle
        @extend .text--h5
    &__desc
        @extend .text--body
    &__data-pickers-container
        flex-direction: column
        display: flex
        gap: 16px
        margin-bottom: 16px
        @include desktop
            flex-direction: row
            gap: 32px
            margin-bottom: 16px
    &__data-picker-container
        flex-direction: column
        display: flex
        @include desktop
            flex-direction: row
            gap: 16px
            align-items: center
    &__data-picker-label
        @extend .text--body
    &__datapicker-pooper
        z-index: 2 !important
    &__datapicker-input
        padding: 15px 16px
        border: 1px solid $colorGrey600
        border-radius: 5px
        height: 49px
    &__wysiwyg
        height: 100%
        min-height: 144px
        box-sizing: border-box
        border-radius: 0px 0px 4px 4px
        border-right: 1px solid $colorGrey300
        border-bottom: 1px solid $colorGrey300
        border-left: 1px solid $colorGrey300
        background: $colorWhite
        padding: 5px
    &__toolbar
        margin-bottom: 0 !important
    &__input-file
        border-radius: 5px
        border: 1px solid $colorPrimary500
        color: $colorPrimary500
    &__logo-container
        display: flex
        flex-direction: column
        gap: 24px
        @include desktop
            flex-direction: row
            gap: 48px

    &__logo-img
        width: auto
        @include desktop
            max-height: 150px
        img
            max-width: 100%
            height: 100%

    &__image-buttons
        display: flex
        flex-direction: row
        gap: 8px

    &__remove-logo-button
        border-radius: 3px
        margin-bottom: 8px
        color: $colorPrimary500
        white-space: nowrap

    &__custom-file-input
        margin-bottom: 8px
        display: inline-block
        background: $colorGrey100
        border: 1px solid $colorPrimary500
        border-radius: 3px
        padding: 0px 8px
        outline: none
        white-space: nowrap
        -webkit-user-select: none
        cursor: pointer
        font-size: 16px
        color: $colorPrimary500
        width: 146px
        height: 49px
        line-height: 49px
        text-align: center
        font-family: Roboto, sans-serif
        font-size: 16px
        font-weight: 400

    &__buttons-container
        display: flex
        gap: 24px
        flex-direction: column
        @include desktop
            flex-direction: row
            justify-content: flex-end
            align-items: center

    &__datapicker-caption
        @extend .text--body-small
        color: $colorGrey700
        margin-bottom: 0
