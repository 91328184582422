@import '@Styles/_breakpoints.sass'
@import '@Styles/_auth.sass'
@import '@Styles/_colors.sass'

.account
    color: $colorBlack
    h2
        +mobile
            font-size: 1.4375rem

    hr
        margin: 35px 0
        border-color: $colorGrey300

    &__block
        margin: 70px auto 30px
        max-width: 880px

    &__remove
        &--wrapper
            display: flex
            justify-content: flex-end

        &--button
            color: $colorError600
            &:hover
                color: $colorError500

    &__table
        &--row            
            margin: 10px 0
            display: grid
            grid-template-columns: 160px 1fr
            align-items: baseline
            grid-gap: 10px
            +mobile-micro
                grid-template-columns: 90px auto
            

        &--value
            &-split
                display: flex
                flex-direction: column
                +tablet
                    flex-direction: row
                    justify-content: space-between
        &--additional-text
            color: #6D7274

    &__groups
        margin-top: 75px
        +desktop
            margin-top: 80px

        &--title
            display: flex
            justify-content: space-between
            +mobile-micro
                flex-direction: column
                
        &--admin, &--rest, &--blank
            margin-top: 25px

    &__adminlist
        margin: 20px 0 60px
        color: $colorBlack

        &--item
            margin: 10px 0
            border: 1px solid $colorGrey300
            border-radius: 5px
            display: grid
            grid-template-columns: 50px auto
            +tablet
                grid-template-columns: 50px auto 125px

            &-bg
                background: $colorGrey100
                &:not(:last-child)
                    border-right: 1px solid $colorGrey300

            & > div
                padding: 8px 10px

            &-lp
                @extend .account__adminlist--item-bg
                text-align: center
                border-radius: 5px 0 0 5px
                +mobile
                    border-radius: 5px 0 0 0

            &-name
                @extend .account__adminlist--item-bg
                +mobile
                    border-right: 0 !important

            &-button
                text-align: center
                +mobile
                    grid-column: 1 / span 2
                    border-top: 1px solid $colorGrey300

    &__password-form
        margin-top: 70px

    &__list
        margin: 20px 0 0
        padding: 0
        list-style-type: none
        color: $colorBlack
        &--item
            display: flex
            +mobile-micro
                flex-direction: column
                & span
                    margin-left: 20px
