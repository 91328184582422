@import '@Styles/_breakpoints.sass'
@import "@Styles/_text.sass"

.events 
    &__title-container
        margin: 64px auto 40px
        text-align: center

    &__title
        @extend .text--h2
    &__button-container
        margin-top: 40px
        text-align: center